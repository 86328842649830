import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Button, Card, CardContent, Box, Tab, Tabs } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import _ from 'lodash';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/th';

import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { ViewTitle } from '../../components/ViewTitle';
import {
  FileInProduct,
  NoteInProduct,
  SystemInProduct,
} from '../../components/SubPage';

import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { Link } from 'react-router-dom';
dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

export default function DetailProduct({ title, subtitle }) {
  const dispatch = useDispatch();

  const params = useParams();
  const product = useSelector((state) => state.product);
  const [selectedTab, setSelectedTab] = useState('table');
  const renderTitle = () => (
    <ViewTitle title={`${title} ${product?.name || ''}`} subtitle={subtitle} />
  );

  useEffect(() => {
    dispatch(actions.productGet(params.id));

    return () => {};
  }, [params]);

  const renderData = () => (
    <div>
      <Card>
        <CardContent>
          <img src={product?.logo?.url} className="h-24" />
          <h3 className="text-2xl">{product?.name}</h3>
          <h3 className="text-lg">{product?.full_title}</h3>
          <hr className="my-2" />
          <h5 className="text-sm my-2 font-semibold">คอนเซปต์หลัก</h5>
          <p>{product?.main_concept} </p>
          <h5 className="text-sm my-2 font-semibold">รายละเอียด</h5>
          <p className="md-content text-sm font-system px-4 mx-2">
            <ReactMarkdown children={product?.description || ''} />
          </p>
        </CardContent>
      </Card>
      <Box sx={{ width: '100%' }} className="my-2">
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            bgcolor: 'background.paper',
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={(event, newValue) => {
              setSelectedTab(newValue);
            }}
            aria-label="basic tabs example"
          >
            <Tab label="รายการระบบ" value="table" />
            <Tab label="บันทึก" value="note" />
            <Tab label="เอกสาร/คู่มือ" value="file" />
          </Tabs>
        </Box>
      </Box>
      {selectedTab === 'table' && <SystemInProduct />}
      {selectedTab === 'note' && <NoteInProduct />}
      {selectedTab === 'file' && <FileInProduct />}
    </div>
  );

  if (product?.isLoading || product?.rows) {
    return <Loading />;
  }

  if (product?.isCompleted && !product?.isLoading && !product?.rows) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-between pb-4">
          <BackButton />
          <div className="flex gap-2">
            <Link to={`/monitor/product/module/${params.id}`}>
              <Button variant="contained" color="secondary">
                รายการโมดูล
              </Button>
            </Link>
            <Link to={`/monitor/product/edit/${params.id}`}>
              <Button variant="contained" color="warning">
                แก้ไข
              </Button>
            </Link>
          </div>
        </div>
        {renderData()}
      </div>
    );
  }

  return <Error />;
}

DetailProduct.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailProduct.defaultProps = {
  title: '',
  subtitle: '',
};
