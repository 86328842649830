import {
  SYSTEM_ALL,
  SYSTEM_GET,
  SYSTEM_POST,
  SYSTEM_PUT,
  SYSTEM_DEL,
  SYSTEM_ERROR,
  SYSTEM_LOADING,
} from '../types';

import api from '../../../utils/functions/api';

export const systemCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: SYSTEM_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/system`,
      payload,
    );
    dispatch({ type: SYSTEM_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: SYSTEM_ERROR });
    throw new Error(error);
  }
};

export const systemAll = ({
  name = '',
  size = null,
  page = 1,
  customer = '',
  product = '',
  advisor = '',
  status = '',
  success = '',
  onPaid = '',
  project = '',
  analyzed = '',
  open = '',
  aptitude = '',
  paid = '',
}) => async (dispatch) => {
  try {
    const { data, status: responseStatus } = await api.get(
      `${process.env.REACT_APP_API_URL}/system?name=${name}&size=${size}&page=${page}&customer=${customer}&product=${product}&advisor=${advisor}&status=${status}&success=${success}&project=${project}&analyzed=${analyzed}&open=${open}&aptitude=${aptitude}&paid=${paid}`,
    );
    if (responseStatus === 200) {
      dispatch({ type: SYSTEM_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: SYSTEM_ERROR });
    throw new Error(error);
  }
};

export const systemGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/system/${id}`,
    );
    if (status === 200) {
      dispatch({ type: SYSTEM_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: SYSTEM_ERROR });
    throw new Error(error);
  }
};

export const systemPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: SYSTEM_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/system/${id}`,
      payload,
    );
    dispatch({ type: SYSTEM_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: SYSTEM_ERROR });
    throw new Error(error);
  }
};

export const systemDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: SYSTEM_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/system/${id}`,
    );
    dispatch({ type: SYSTEM_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: SYSTEM_ERROR });
    throw new Error(error);
  }
};
