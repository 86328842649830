import React, { useState } from 'react';
import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
  useHistory,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { Collapse, List, ListItemButton, Accordion } from '@mui/material';
import { ChevronRight, ChevronDown } from 'react-feather';
import _ from 'lodash';
// import accessRight from '../../utils/functions/accessRight';

const NavItem = ({
  href,
  icon: Icon,
  title,
  sub,
  moduleName,
  me,
  outside = false,
}) => {
  const history = useHistory();
  const location = useLocation();
  const active = href
    ? !!matchPath(location.pathname, {
        path: href,
      })
    : false;
  const [open, setOpen] = useState(active);

  const handleClick = () => {
    setOpen(!open);
    if (outside) {
      window.open(href, '_blank');
    } else {
      history.push(href);
    }
  };

  return (
    <div className="py-2">
      <Accordion
        elevation={0}
        expanded={open}
        onClick={() => {
          handleClick();
        }}
        sx={{
          ...(active && {
            color: 'primary.main',
          }),
        }}
        TransitionProps={{ unmountOnExit: true }}
        className="py-2 px-2 cursor-pointer"
      >
        <div className="flex gap-2">
          {Icon && <Icon size="20" />}
          <span>{title}</span>
          {_.size(sub) ? (
            <div>
              {open ? <ChevronDown size="20" /> : <ChevronRight size="20" />}
            </div>
          ) : (
            <></>
          )}
        </div>
      </Accordion>
      {_.size(sub) ? (
        <Collapse in={open} timeout="auto" unmountOnExit>
          {_.map(sub, (item, index) => {
            // if (accessRight(me, moduleName, item.level)) {
            return (
              <div key={index}>
                <List component="div" disablePadding>
                  <ListItemButton
                    component={RouterLink}
                    to={item.href}
                    sx={{
                      pl: 6,
                      ...(item.href === location.pathname && {
                        color: 'primary.main',
                        backgroundColor: 'grey.100',
                      }),
                    }}
                  >
                    <div className="font-light text-sm">{item.title}</div>
                  </ListItemButton>
                </List>
              </div>
            );
            // }
            // return <></>;
          })}
        </Collapse>
      ) : (
        <></>
      )}
    </div>
  );
};

NavItem.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string,
  sub: PropTypes.array,
  me: PropTypes.object,
  moduleName: PropTypes.string,
};

export default NavItem;
