import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Button, Card } from '@mui/material';
import PropTypes from 'prop-types';
import _ from 'lodash';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/th';

import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { ViewTitle } from '../../components/ViewTitle';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { SystemTable } from '../../components/Table';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

export default function DetailCustomer({ title, subtitle }) {
  const dispatch = useDispatch();
  const params = useParams();
  const customer = useSelector((state) => state.customer);
  const system = useSelector((state) => state.system);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);
  useEffect(() => {
    dispatch(actions.customerGet(params.id));

    return () => {};
  }, [params]);

  useEffect(() => {
    dispatch(
      actions.systemAll({
        name: '',
        size,
        page,
        customer: params.id,
      }),
    );

    return () => {};
  }, [page, size]);

  useEffect(() => {
    setTotal(system?.total);
    return () => {};
  }, [system]);

  const renderData = () => (
    <div>
      <Card className="p-4">
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/5">
            <img src={customer?.logo?.url} className="h-32" />
          </div>
          <div className="w-full md:w-3/5">
            <h3 className="text-2xl">{customer?.name}</h3>
            <p>
              <b>เลขประจำตัวผู้เสียภาษี :</b> {customer?.tax_id || '-'}
            </p>
            <p>
              <b>ที่อยู่ :</b> {customer?.address || '-'}
            </p>
            <p>
              <b>จังหวัด :</b> {customer?.province || '-'}
            </p>
            <p>
              <b>ประเภทกิจการ :</b> {customer?.buisness_type || '-'}
            </p>
          </div>
        </div>
      </Card>
      <Card className="p-4 my-2">
        <h3 className="text-sm">รายละเอียด</h3>
        <p>{customer?.description}</p>
      </Card>
      <Card className="p-4 my-2">
        <h3 className="text-sm">ผู้ติดต่อ</h3>
        {_.map(customer?.contacts, (eachContact, index) => (
          <div key={index} className="my-2 mx-1 flex flex-wrap ">
            <div className="w-1/3">
              <b>ชื่อ:</b> {eachContact?.name}
            </div>
            <div className="w-1/3">
              <b>ตำแหน่ง:</b> {eachContact?.position || '-'}
            </div>
            <div className="w-1/3">
              <b>หมายเลขโทรศัพท์:</b> {eachContact?.tel || '-'}
            </div>
          </div>
        ))}
      </Card>
    </div>
  );

  const handleDeleteSystem = async (id) => {
    const confirm = window.confirm('ยืนยันการลบ');
    if (confirm) {
      await dispatch(actions.systemDelete(id));
      await dispatch(actions.systemAll({ size, page, customer: params.id }));
    }
  };

  const handleEditSystem = async (id, payload) => {
    try {
      await dispatch(actions.systemPut(id, payload));
      await dispatch(actions.systemAll({ size, page, customer: params.id }));
    } catch (error) {
      alert(error.message);
    }
  };

  const renderTable = () => (
    <SystemTable
      handleDeleteSystem={handleDeleteSystem}
      page={page}
      setPage={setPage}
      size={size}
      setSize={setSize}
      system={system}
      total={total}
      handleEditSystem={handleEditSystem}
    />
  );

  if (customer?.isLoading || customer?.rows) {
    return <Loading />;
  }

  if (customer?.isCompleted && !customer?.isLoading && !customer?.rows) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        {renderData()}
        {renderTable()}
      </div>
    );
  }

  return <Error />;
}

DetailCustomer.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailCustomer.defaultProps = {
  title: '',
  subtitle: '',
};
