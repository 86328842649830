import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { useHistory, Link } from 'react-router-dom';
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Button,
  Card,
  TextField,
  InputAdornment,
  Modal,
  Fade,
  Backdrop,
} from '@mui/material';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as actions from '../../redux/actions';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { CustomerForm } from '../../components/Form';
import { ImageUpload } from '../../components/ImageUpload';

import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/th';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  boxShadow: 24,
  p: 3,
};

export default function CustomerManagement({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const customer = useSelector((state) => state.customer);

  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);
  const [open, setOpen] = useState(false);
  const [images, setImages] = useState([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  useEffect(() => {
    dispatch(actions.customerAll({ name, size, page }));
    return () => {};
  }, [name, size, page]);

  useEffect(() => {
    setTotal(customer?.total);
    return () => {};
  }, [customer]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data) => {
    try {
      const payload = {
        ...data,
        logo: _.isEmpty(images)
          ? null
          : { image: images?.[0]?.data_url, alt: '' },
      };
      await dispatch(actions.customerCreate(payload));
      alert('สำเร็จ');
      handleClose();
      await dispatch(actions.customerAll({ name, size, page }));
    } catch (error) {
      alert(error.message);
      console.error(error);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleDeleteProject = async (id) => {
    const confirm = window.confirm('ยืนยันการลบ');
    if (confirm) {
      await dispatch(actions.customerDelete(id));
      await dispatch(actions.customerAll({ size, page }));
    }
  };

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </Card>
  );

  const renderModal = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Card sx={style} className="max-w-4xl">
          <div className="text-lg font-semibold">เพิ่มข้อมูล</div>
          <div className="py-2">
            <form onSubmit={handleSubmit(onSubmit)}>
              <CustomerForm control={control} errors={errors} />
              <ImageUpload
                images={images}
                maxNumber={1}
                setImages={setImages}
              />
              <div className="flex flex-row justify-end gap-1 mt-2">
                <Button variant="contained" type="submit">
                  บันทึก
                </Button>
                <Button variant="outlined" onClick={handleClose}>
                  ยกเลิก
                </Button>
              </div>
            </form>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderProject = () => (
    <div className="mt-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size={'small'}>
            <TableHead>
              <TableRow className="bg-gray-200">
                <TableCell>
                  <div className="font-bold py-1">ลำดับที่</div>
                </TableCell>

                <TableCell>
                  <div className="font-bold py-1">โลโก้</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ชื่อ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">จังหวัด</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableHead>
              {!_.isEmpty(customer?.rows) ? (
                _.map(customer?.rows, (row, index) => (
                  <TableRow key={row?._id}>
                    <TableCell component="th" scope="row">
                      <div className="py-1">{`${
                        (page - 1) * size + index + 1
                      }`}</div>
                    </TableCell>{' '}
                    <TableCell component="th" scope="row">
                      <div className="py-1">
                        <img src={row?.logo?.url} className="h-16" />
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="py-1">{`${row?.name}`}</div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="py-1">{`${row?.province || '-'}`}</div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="flex flex-wrap gap-1">
                        <Link to={`/monitor/customer/detail/${row?._id}`}>
                          <Button variant="outlined" color="info" size="small">
                            รายละเอียด
                          </Button>{' '}
                        </Link>
                        <Button
                          variant="outlined"
                          color="warning"
                          size="small"
                          onClick={() => {
                            history.push('/monitor/customer/edit/' + row._id);
                          }}
                        >
                          แก้ไข
                        </Button>
                        <Button
                          variant="outlined"
                          color="error"
                          size="small"
                          onClick={() => {
                            handleDeleteProject(row?._id);
                          }}
                        >
                          ลบ
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableHead>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  const renderAddButton = () => (
    <div className="flex flex-row justify-end mb-4">
      <Button variant="contained" size="small" onClick={handleOpen}>
        เพิ่ม
      </Button>
    </div>
  );

  if (customer.isLoading) {
    return <Loading />;
  }

  if (!customer.isLoading && customer.isCompleted) {
    return (
      <div>
        {renderModal()}
        {renderTitle()}
        <div className="flex flex-row justify-end">
          {/* <div>
            <BackButton />
          </div> */}
          <div>{renderAddButton()}</div>
        </div>
        {renderSearch()}
        {renderProject()}
      </div>
    );
  }

  return <Error message={customer?.message} />;
}

CustomerManagement.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CustomerManagement.defaultProps = {
  title: '',
  subtitle: '',
};
