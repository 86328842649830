import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as actions from '../../redux/actions';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { SystemTable } from '../../components/Table';
import MuiAlert from '@mui/material/Alert';
import copy from 'copy-to-clipboard';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/th';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

export default function SystemManagement({ title, subtitle }) {
  const dispatch = useDispatch();
  const system = useSelector((state) => state.system);
  const project = useSelector((state) => state.project);
  const product = useSelector((state) => state.product);

  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);
  const [selectedProject, setSelectedProject] = useState();
  const [selectedProduct, setSelectedProduct] = useState();
  const [open, setOpen] = useState(true);
  const [aptitude, setAptitude] = useState(false);
  const [success, setSuccess] = useState(false);
  const [paid, setPaid] = useState(false);

  useEffect(() => {
    dispatch(
      actions.systemAll({
        name,
        size,
        page,
        product: selectedProduct || '',
        project: selectedProject || '',
        open: open || '',
        aptitude: aptitude || '',
        success: success || '',
        paid: paid || '',
      }),
    );
    dispatch(actions.projectAll({ size: 500, page: 1 }));
    dispatch(actions.productAll({ size: 500, page: 1 }));
    return () => {};
  }, [
    name,
    size,
    page,
    selectedProject,
    selectedProduct,
    open,
    aptitude,
    success,
    paid,
  ]);

  useEffect(() => {
    setTotal(system?.total);
    return () => {};
  }, [system]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const handleEditSystem = async (id, payload) => {
    try {
      await dispatch(actions.systemPut(id, payload));
      dispatch(
        actions.systemAll({
          name,
          size,
          page,
          product: selectedProduct || '',
          project: selectedProject || '',
          open: open || '',
          aptitude: aptitude || '',
          success: success || '',
          paid: paid || '',
        }),
      );
    } catch (error) {
      alert(error.message);
    }
  };

  const renderTitle = () => (
    <div className="flex justify-between">
      <ViewTitle title={title} subtitle={subtitle} />
      <Link to={`/monitor/system/create`} className="self-center">
        <Button variant="contained" size="small">
          เพิ่ม
        </Button>
      </Link>
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row flex-wrap">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="w-full md:w-1/6 px-1">
          <FormControl size="small" fullWidth>
            <InputLabel>โครงการ</InputLabel>
            <Select
              size="small"
              label="โครงการ"
              fullWidth
              value={selectedProject}
              onChange={(event) => setSelectedProject(event.target.value)}
            >
              <MenuItem value={null}>ไม่เลือก</MenuItem>
              {_.map(project?.rows, (eachProject, index) => (
                <MenuItem key={index} value={eachProject?._id}>
                  {eachProject?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="w-full md:w-1/6 px-1">
          <FormControl size="small" fullWidth>
            <InputLabel>โปรดักส์</InputLabel>
            <Select
              size="small"
              label="โปรดักส์"
              fullWidth
              value={selectedProduct}
              onChange={(event) => setSelectedProduct(event.target.value)}
            >
              <MenuItem value={null}>ไม่เลือก</MenuItem>
              {_.map(product?.rows, (eachProduct, index) => (
                <MenuItem key={index} value={eachProduct?._id}>
                  {eachProduct?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="md:w-1/6 px-1 gap-2">
          <div className="text-sm">จำนวน</div>
          <div className="text-lg font-semibold">{system?.total} ระบบ</div>
        </div>
        <div className="w-full  px-1">
          <FormControlLabel
            size="small"
            fullWidth
            label="เฉพาะที่เปิดอยู่"
            control={
              <Checkbox checked={open} onChange={(e) => setOpen(!open)} />
            }
          />
          <FormControlLabel
            size="small"
            fullWidth
            label="เฉพาะที่รู้สึกดี / มีโอกาสสำเร็จ"
            control={
              <Checkbox
                checked={aptitude}
                onChange={(e) => setAptitude(!aptitude)}
              />
            }
          />
          <FormControlLabel
            size="small"
            fullWidth
            label="เฉพาะที่สำเร็จ"
            control={
              <Checkbox
                checked={success}
                onChange={(e) => setSuccess(!success)}
              />
            }
          />
          <FormControlLabel
            size="small"
            fullWidth
            label="เฉพาะที่จ่ายเงิน"
            control={
              <Checkbox checked={paid} onChange={(e) => setPaid(!paid)} />
            }
          />
        </div>
      </div>
    </Card>
  );

  const handleDeleteSystem = async (id) => {
    const confirm = window.confirm('ยืนยันการลบ');
    if (confirm) {
      await dispatch(actions.systemDelete(id));
      await dispatch(actions.systemAll({ size, page }));
    }
  };

  const renderSystem = () => (
    <SystemTable
      page={page}
      setPage={setPage}
      size={size}
      setSize={setSize}
      system={system}
      total={total}
      handleDeleteSystem={handleDeleteSystem}
      handleEditSystem={handleEditSystem}
    />
  );

  return (
    <div>
      {renderTitle()}

      {renderSearch()}
      {!system.isLoading && system.isCompleted ? renderSystem() : <Loading />}
    </div>
  );
}

SystemManagement.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

SystemManagement.defaultProps = {
  title: '',
  subtitle: '',
};
