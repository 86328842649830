import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import {
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/th';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

export default function DetailDroplet({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const droplet = useSelector((state) => state.droplet);

  useEffect(() => {
    dispatch(actions.dropletGet(id));
    return () => {};
  }, [id]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderDetail = () => (
    <Card className="p-4 mb-4">
      <h3 className="text-2xl">{droplet?.name}</h3>
      <div className="w-full flex flex-row ">
        <div className="w-full lg:w-2/3 py-1">
          {' '}
          <p className="text-xl">
            <b>โปรเจค</b> {droplet?.type?.name}
          </p>
          <p>คำอธิบาย : {droplet?.type?.description || '-'}</p>
          <p>จุดประสงค์ : {droplet?.type?.purpose || '-'}</p>
        </div>
        <div className="w-full lg:w-1/3 py-1">
          <b className="text-xl">networks</b>
          {_.map(droplet?.networks?.v4, (address) => (
            <div className="py-1" key={address?._id}>
              - {`${address?.ip_address} ( ${address.type} )`}
            </div>
          ))}
        </div>
      </div>
      <div className="w-full flex gap-2">
        <div className="w-full lg:w-1/3 bg-gray-100 shadow-lg rounded-lg border p-4 sm:p-6 xl:p-8 ">
          <div className=" gap-4">
            <b>Region</b>
            <div className="flex-shrink-0">
              <span className="text-base font-normal text-gray-500">
                {droplet?.region?.name}
              </span>
              <h3 className="text-base font-normal text-gray-500">
                slug : {droplet?.region?.slug}
              </h3>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-2/3 bg-gray-100 shadow-lg rounded-lg border p-4 sm:p-6 xl:p-8 ">
          <div className=" gap-4">
            <b>Size</b>
            <div className="flex-shrink-0">
              <span className="text-base font-normal text-gray-500">
                description : {droplet?.size?.description}
              </span>
              <h3 className="text-base font-normal text-gray-500">
                slug : {droplet?.size?.slug}
              </h3>
              <h3 className="text-base font-normal text-gray-500">
                disk : {droplet?.size?.disk}
              </h3>
              <h3 className="text-base font-normal text-gray-500">
                memory : {droplet?.size?.memory}
              </h3>
              <h3 className="text-base font-normal text-gray-500">
                vcpus : {droplet?.size?.vcpus}
              </h3>
              <h3 className="text-base font-normal text-gray-500">
                transfer : {droplet?.size?.transfer}
              </h3>
              <h3 className="text-base font-normal text-gray-500">
                price_monthly : ${droplet?.size?.price_monthly}/month
              </h3>
              <h3 className="text-base font-normal text-gray-500">
                available :{' '}
                <Checkbox defaultChecked={droplet?.size?.available} disabled />
              </h3>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );

  const renderSystem = () => {
    if (droplet?.systems) {
      return _.map(droplet?.systems, (_system) => (
        <div key={_system?._id}>
          <div className="w-full bg-white p-4 h-auto tracking-wide mb-4 border border-black-800  rounded-lg relative">
            <div className="small-banner w-1 h-20 bg-blue-600 absolute rounded-tl-md"></div>
            <div className="flex justify-between">
              <h5 className="text-2xl font-semibold p-6">
                ระบบ {`${_system?.name}`}
              </h5>{' '}
              <div className="gap-2">
                <b>แท็ก</b>
                <p className="text-sm bg-gray-200 px-3 py-1 rounded-full font-medium text-gray-800 hidden md:block">
                  {`${_system?.tag || '-'}`}
                </p>
              </div>
            </div>
            <p className="text-sm px-6 text-gray-500">
              {`${_system?.description || ''}`}
            </p>
            <div className="p-2 flex flex-wrap w-full">
              <div className="w-full lg:w-1/3 bg-gray-100 p-6  rounded-xl border shadow-md border-gray-50">
                <p className="text-gray-500 text-sm">URL</p>
                <p
                  className="text-center text-xl font-semibold text-blue-600 hover:text-blue-400 hover:underline cursor-pointer"
                  onClick={() => window.open(`${_system?.url}`)}
                >
                  {`${_system?.url || ''}`}
                </p>
              </div>
              <div className="w-full lg:w-1/3 bg-gray-100 p-6  rounded-xl border shadow-md border-gray-50">
                <p className="text-gray-500 text-sm">Database</p>
                <p className="text-center text-xl font-bold">
                  {`${_system?.database?.name || '-'}`}{' '}
                </p>
              </div>
              <div className="w-full lg:w-1/3 bg-gray-100 p-6 rounded-xl border shadow-md border-gray-50">
                <p className="text-gray-500 text-sm"> MQTT</p>
                <p className="text-center text-xl font-bold">
                  {`${_system?.mqtt?.topic || '-'}`}{' '}
                </p>
              </div>
            </div>

            <div className="px-2">
              <b>Docker</b>
              <TableContainer>
                <Table size={'small'}>
                  <colgroup>
                    <col width="5%" />
                    <col width="10%" />
                    <col width="10%" />
                  </colgroup>
                  <TableHead>
                    <TableRow className="bg-gray-200">
                      <TableCell>
                        <div className="font-bold py-1">ลำดับที่</div>
                      </TableCell>
                      <TableCell>
                        <div className="font-bold py-1">ชื่อคอนเทนเนอร์</div>
                      </TableCell>
                      <TableCell>
                        <div className="font-bold py-1">พอร์ต</div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableHead>
                    {!_.isEmpty(_system?.docker) ? (
                      _.map(_system?.docker, (_docker, index) => (
                        <TableRow key={_docker?._id}>
                          <TableCell component="th" scope="row">
                            <div className="py-1">{`${index + 1}`}</div>
                          </TableCell>
                          <TableCell>
                            <div className="font-bold py-1">
                              {_docker?.containerName}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="font-bold py-1">
                              {_docker?.port}
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3}>
                          <div className="text-center">ไม่มีข้อมูล</div>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableHead>
                </Table>
              </TableContainer>
            </div>
            <div className="p-2">
              <p className="text-base font-regular">
                <b>โครงการ</b> {`${_system?.project?.name || ''}`} ({' '}
                {dayjs(_system?.project?.start_date)
                  .locale('th')
                  .format('DD MMM BBBB')}{' '}
                -{' '}
                {dayjs(_system?.project?.end_date)
                  .locale('th')
                  .format('DD MMM BBBB')}
                )
              </p>
              <p className="text-sm font-regular text-gray-500">
                {`${_system?.project?.description || ''}`}
              </p>
            </div>

            <p className="text-sm px-2">
              หมายเหตุ {`${_system?.remark || ''}`}
            </p>
          </div>
        </div>
      ));
    }
  };

  if (droplet.isLoading || droplet.rows) {
    return <Loading />;
  }

  if (!droplet.isLoading && droplet.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        {renderDetail()}
        {renderSystem()}
      </div>
    );
  }

  return <Error message={droplet?.message} />;
}

DetailDroplet.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailDroplet.defaultProps = {
  title: '',
  subtitle: '',
};
