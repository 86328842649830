import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Checkbox,
  FormControlLabel,
  Switch,
  Autocomplete,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import thLocale from 'date-fns/locale/th';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import React from 'react';
import PropTypes from 'prop-types';

export function SystemForm({
  errors,
  system,
  project,
  droplet,
  dropletProject,
  Controller,
  control,
  dockerFields,
  dockerAppend,
  dockerRemove,
  userFields,
  userAppend,
  userRemove,
  getValues,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  advisor,
  product,
  customer,
}) {
  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor:
            theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));
  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-2">
        <Controller
          name={'name'}
          control={control}
          defaultValue={system ? system.name : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อ"
              required
              fullWidth
              size={'small'}
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'description'}
          control={control}
          defaultValue={system ? system.description : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="คำอธิบาย"
              fullWidth
              multiline={true}
              rows={3}
              size={'small'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'url'}
          control={control}
          defaultValue={system ? system.url : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="URL"
              fullWidth
              multiline={true}
              size={'small'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        {!_.isEmpty(product?.rows) && (
          <Controller
            name={'product'}
            control={control}
            defaultValue={system ? system.product : null}
            render={({ field }) => (
              <Autocomplete
                {...field}
                options={product.rows}
                fullWidth
                onChange={(e, newValue) => {
                  field.onChange(newValue);
                }}
                getOptionLabel={(option) => `${option?.name || ''} `}
                renderInput={(params) => (
                  <TextField {...params} label="โปรดักส์" />
                )}
              />
            )}
          />
        )}
      </div>
      <div className="w-full px-1 py-2">
        {!_.isEmpty(project?.rows) && (
          <Controller
            name={'project'}
            control={control}
            defaultValue={system ? system.project : null}
            render={({ field }) => (
              <Autocomplete
                {...field}
                options={project.rows}
                fullWidth
                onChange={(e, newValue) => {
                  field.onChange(newValue);
                }}
                getOptionLabel={(option) => `${option?.name || ''} `}
                renderInput={(params) => (
                  <TextField {...params} label="โครงการ" />
                )}
              />
            )}
          />
        )}
      </div>
      <div className="w-1/2 px-1 py-2">
        {!_.isEmpty(customer?.rows) && (
          <Controller
            name={'customer'}
            control={control}
            defaultValue={system ? system.customer : null}
            render={({ field }) => (
              <Autocomplete
                {...field}
                options={customer.rows}
                fullWidth
                onChange={(e, newValue) => {
                  field.onChange(newValue);
                }}
                getOptionLabel={(option) => `${option?.name || ''} `}
                renderInput={(params) => (
                  <TextField {...params} label="ลูกค้า / ผู้รับบริการ" />
                )}
              />
            )}
          />
        )}
      </div>
      <div className="w-1/2 px-1 py-2">
        {!_.isEmpty(advisor?.rows) && (
          <Controller
            name={'advisor'}
            control={control}
            defaultValue={system ? system.advisor : null}
            render={({ field }) => (
              <Autocomplete
                {...field}
                options={advisor.rows}
                fullWidth
                onChange={(e, newValue) => {
                  field.onChange(newValue);
                }}
                getOptionLabel={(option) => `${option?.name || ''} `}
                renderInput={(params) => (
                  <TextField {...params} label="ที่ปรึกษา" />
                )}
              />
            )}
          />
        )}
      </div>
      <div className="w-1/2 px-1 py-2">
        {!_.isEmpty(droplet?.rows) && (
          <Controller
            name={'droplet'}
            control={control}
            defaultValue={system ? system.droplet : null}
            render={({ field }) => (
              <Autocomplete
                {...field}
                options={droplet.rows}
                fullWidth
                onChange={(e, newValue) => {
                  field.onChange(newValue);
                }}
                getOptionLabel={(option) => `${option?.name || ''} `}
                required
                renderInput={(params) => (
                  <TextField {...params} label="Droplet" />
                )}
              />
            )}
          />
        )}
      </div>
      <div className="flex w-full gap-2 px-1 py-2">
        <div className="w-full lg:w-1/2">
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={thLocale}>
            <DatePicker
              label="วันที่เริ่ม"
              value={startDate}
              onChange={(newValue) => {
                setStartDate(newValue);
              }}
              renderInput={(params) => (
                <TextField fullWidth {...params} required />
              )}
            />
          </LocalizationProvider>
        </div>
        <div className="w-full lg:w-1/2">
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={thLocale}>
            <DatePicker
              label="วันที่สิ้นสุด"
              value={endDate}
              onChange={(newValue) => {
                setEndDate(newValue);
              }}
              renderInput={(params) => (
                <TextField fullWidth {...params} required />
              )}
            />
          </LocalizationProvider>
        </div>
      </div>
      <div className="w-full lg:w-1/3 px-2 flex flex-row gap-2">
        <div className="font-semibold my-2">สถานะการใช้งาน</div>
        <div className="mx-4">
          <Controller
            name={'status'}
            control={control}
            defaultValue={system ? system.status : false}
            render={({ field }) => (
              <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                <IOSSwitch
                  {...field}
                  sx={{ m: 1 }}
                  defaultChecked={system ? system.status : false}
                />
              </FormControl>
            )}
          />
        </div>
      </div>{' '}
      <div className="w-full lg:w-1/3 px-2 flex flex-row gap-2">
        <div className="font-semibold my-2">ระบบ Success</div>
        <div className="mx-4">
          <Controller
            name={'success'}
            control={control}
            defaultValue={system ? system.success : false}
            render={({ field }) => (
              <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                <IOSSwitch
                  {...field}
                  sx={{ m: 1 }}
                  defaultChecked={system ? system.success : false}
                />
              </FormControl>
            )}
          />
        </div>
      </div>{' '}
      <div className="w-full lg:w-1/3  px-2 flex flex-row gap-2">
        <div className="font-semibold my-2">ชำระเงิน</div>
        <div className="mx-4">
          <Controller
            name={'on_paid'}
            control={control}
            defaultValue={system ? system.on_paid : false}
            render={({ field }) => (
              <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                <IOSSwitch
                  {...field}
                  sx={{ m: 1 }}
                  defaultChecked={system ? system.on_paid : false}
                />
              </FormControl>
            )}
          />
        </div>
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'version'}
          control={control}
          defaultValue={system ? system.version : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="เวอร์ชั่น"
              fullWidth
              multiline={true}
              size={'small'}
            />
          )}
        />
      </div>
      <div className="w-full font-semibold my-2">Docker</div>
      <TableContainer>
        <Table size="small" className="border-lg" sx={{ minWidth: 650 }}>
          <colgroup>
            <col width="10%" />
            <col width="20%" />
            <col width="20%" />
            <col width="10%" />
          </colgroup>
          <TableHead className="bg-blue-100">
            <TableRow>
              <TableCell>
                <div className="font-bold">ลำดับที่</div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> ชื่อคอนเทนเนอร์</div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> พอร์ต</div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> ดำเนินการ</div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_.size(dockerFields) ? (
              dockerFields.map((_field, index) => {
                return (
                  <TableRow key={_field.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      <div className="w-36">
                        <Controller
                          name={`docker.[${index}].containerName`}
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label="ชื่อคอนเทนเนอร์"
                              fullWidth
                              required
                              size={'small'}
                              helperText={
                                errors.containerName && 'กรุณาใส่ข้อมูล'
                              }
                            />
                          )}
                        />
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="w-48">
                        <Controller
                          name={`docker.[${index}].port`}
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label="พอร์ต"
                              fullWidth
                              required
                              size={'small'}
                              helperText={errors.port && 'กรุณาใส่ข้อมูล'}
                            />
                          )}
                        />
                      </div>
                    </TableCell>
                    <TableCell>
                      <Button
                        color={'error'}
                        variant="contained"
                        size={'small'}
                        onClick={() => dockerRemove(index)}
                      >
                        ลบ
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={4}>
                  <div className="text-center py-2">ไม่มีรายการ</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="w-full px-2 py-2">
        <Button
          color={'success'}
          variant="outlined"
          size={'small'}
          onClick={() =>
            dockerAppend({
              containerName: '',
              port: '',
            })
          }
        >
          เพิ่มรายการ
        </Button>
      </div>
      <div className="w-full font-semibold my-2">บัญชีผู้ใช้</div>
      <TableContainer>
        <Table size="small" className="border-lg" sx={{ minWidth: 650 }}>
          <colgroup>
            <col width="10%" />
            <col width="20%" />
            <col width="20%" />
            <col width="10%" />
            <col width="10%" />
          </colgroup>
          <TableHead className="bg-blue-100">
            <TableRow>
              <TableCell>
                <div className="font-bold">ลำดับที่</div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> ชือผู้ใช้</div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> รหัสผ่าน</div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> สิทธิผู้ใช้</div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> ดำเนินการ</div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_.size(userFields) ? (
              userFields.map((_field, index) => {
                return (
                  <TableRow key={_field.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      <div className="w-36">
                        <Controller
                          name={`user.[${index}].user`}
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label="ชื่อผู้ใช้"
                              fullWidth
                              required
                              size={'small'}
                            />
                          )}
                        />
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="w-48">
                        <Controller
                          name={`user.[${index}].password`}
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label="รหัสผ่าน"
                              fullWidth
                              required
                              size={'small'}
                            />
                          )}
                        />
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="w-48">
                        <Controller
                          name={`user.[${index}].superuser`}
                          control={control}
                          render={({ field }) => (
                            <FormControlLabel
                              {...field}
                              control={
                                <Checkbox
                                  checked={getValues(
                                    `user.[${index}].superuser`,
                                  )}
                                />
                              }
                              label="Superuser"
                            />
                          )}
                        />
                      </div>
                    </TableCell>
                    <TableCell>
                      <Button
                        color={'error'}
                        variant="contained"
                        size={'small'}
                        onClick={() => userRemove(index)}
                      >
                        ลบ
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={4}>
                  <div className="text-center py-2">ไม่มีรายการ</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="w-full px-2 py-2">
        <Button
          color={'success'}
          variant="outlined"
          size={'small'}
          onClick={() =>
            userAppend({
              user: '',
              password: '',
              superuser: false,
            })
          }
        >
          เพิ่มรายการ
        </Button>
      </div>
      <div className="w-1/2 px-1 py-2">
        <Controller
          name={'database.name'}
          control={control}
          defaultValue={system ? system.database?.name : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="database"
              fullWidth
              multiline={true}
              size={'small'}
            />
          )}
        />
      </div>
      <div className="w-1/2 px-1 py-2">
        <Controller
          name={'mqtt.topic'}
          control={control}
          defaultValue={system ? system?.mqtt?.topic : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="MQTT"
              fullWidth
              multiline={true}
              size={'small'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'tag'}
          control={control}
          defaultValue={system ? system.tag : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="แท็ก"
              fullWidth
              multiline={true}
              size={'small'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'portainer_url'}
          control={control}
          defaultValue={system ? system.portainer_url : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="URL ไปยัง Stack หรือ Container ใน Portainer"
              fullWidth
              multiline={true}
              size={'small'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'remark'}
          control={control}
          defaultValue={system ? system.remark : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="หมายเหตุ"
              fullWidth
              multiline={true}
              rows={3}
              size={'small'}
            />
          )}
        />
      </div>
    </div>
  );
}

SystemForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.object,
    description: PropTypes.object,
    containerName: PropTypes.object,
    port: PropTypes.object,
  }),
  system: PropTypes.object,
  project: PropTypes.object,
  droplet: PropTypes.object,
  dropletProject: PropTypes.object,
  Controller: PropTypes.func,
  control: PropTypes.object,
  dockerFields: PropTypes.array,
  dockerAppend: PropTypes.func,
  dockerRemove: PropTypes.func,
  userFields: PropTypes.array,
  userAppend: PropTypes.func,
  userRemove: PropTypes.func,
  getValues: PropTypes.func,
  startDate: PropTypes.date,
  endDate: PropTypes.date,
  setStartDate: PropTypes.func,
  setEndDate: PropTypes.func,
  product: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  advisor: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  customer: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
};

SystemForm.defaultProps = {
  project: null,
  droplet: null,
  dropletProject: null,
};

export default SystemForm;
