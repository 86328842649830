// common type

export const ME_GET = 'ME_GET';
export const ME_RESET = 'ME_RESET';

export const USER_ALL = 'USER_ALL';
export const USER_GET = 'USER_GET';
export const USER_POST = 'USER_POST';
export const USER_PUT = 'USER_PUT';
export const USER_DEL = 'USER_DEL';
export const USER_CREATE = 'USER_CREATE';
export const USER_LOADING = 'USER_LOADING';
export const USER_ERROR = 'USER_ERROR';

// feature type
export const ROLETYPES_ALL = 'ROLETYPES_ALL';
export const ROLETYPES_GET = 'ROLETYPES_GET';
export const ROLETYPES_POST = 'ROLETYPES_POST';
export const ROLETYPES_PUT = 'ROLETYPES_PUT';
export const ROLETYPES_DEL = 'ROLETYPES_DEL';
export const ROLETYPES_LOADING = 'ROLETYPES_LOADING';
export const ROLETYPES_ERROR = 'ROLETYPES_ERROR';

export const EMPLOYEE_ALL = 'EMPLOYEE_ALL';
export const EMPLOYEE_GET = 'EMPLOYEE_GET';
export const EMPLOYEE_POST = 'EMPLOYEE_POST';
export const EMPLOYEE_PUT = 'EMPLOYEE_PUT';
export const EMPLOYEE_DEL = 'EMPLOYEE_DEL';
export const EMPLOYEE_RESET = 'EMPLOYEE_RESET';
export const EMPLOYEE_LOADING = 'EMPLOYEE_LOADING';
export const EMPLOYEE_ERROR = 'EMPLOYEE_ERROR';

export const DROPLET_PROJECT_ALL = 'DROPLET_PROJECT_ALL';
export const DROPLET_PROJECT_GET = 'DROPLET_PROJECT_GET';
export const DROPLET_PROJECT_POST = 'DROPLET_PROJECT_POST';
export const DROPLET_PROJECT_PUT = 'DROPLET_PROJECT_PUT';
export const DROPLET_PROJECT_DEL = 'DROPLET_PROJECT_DEL';
export const DROPLET_PROJECT_ERROR = 'DROPLET_PROJECT_ERROR';
export const DROPLET_PROJECT_LOADING = 'DROPLET_PROJECT_LOADING';

export const DROPLET_ALL = 'DROPLET_ALL';
export const DROPLET_GET = 'DROPLET_GET';
export const DROPLET_POST = 'DROPLET_POST';
export const DROPLET_PUT = 'DROPLET_PUT';
export const DROPLET_DEL = 'DROPLET_DEL';
export const DROPLET_ERROR = 'DROPLET_ERROR';
export const DROPLET_LOADING = 'DROPLET_LOADING';

export const PROJECT_ALL = 'PROJECT_ALL';
export const PROJECT_GET = 'PROJECT_GET';
export const PROJECT_POST = 'PROJECT_POST';
export const PROJECT_PUT = 'PROJECT_PUT';
export const PROJECT_DEL = 'PROJECT_DEL';
export const PROJECT_ERROR = 'PROJECT_ERROR';
export const PROJECT_LOADING = 'PROJECT_LOADING';

export const SYSTEM_ALL = 'SYSTEM_ALL';
export const SYSTEM_GET = 'SYSTEM_GET';
export const SYSTEM_POST = 'SYSTEM_POST';
export const SYSTEM_PUT = 'SYSTEM_PUT';
export const SYSTEM_DEL = 'SYSTEM_DEL';
export const SYSTEM_ERROR = 'SYSTEM_ERROR';
export const SYSTEM_LOADING = 'SYSTEM_LOADING';

export const PRODUCT_ALL = 'PRODUCT_ALL';
export const PRODUCT_GET = 'PRODUCT_GET';
export const PRODUCT_POST = 'PRODUCT_POST';
export const PRODUCT_PUT = 'PRODUCT_PUT';
export const PRODUCT_DEL = 'PRODUCT_DEL';
export const PRODUCT_ERROR = 'PRODUCT_ERROR';
export const PRODUCT_LOADING = 'PRODUCT_LOADING';

export const PRODUCT_MODULE_ALL = 'PRODUCT_MODULE_ALL';
export const PRODUCT_MODULE_GET = 'PRODUCT_MODULE_GET';
export const PRODUCT_MODULE_POST = 'PRODUCT_MODULE_POST';
export const PRODUCT_MODULE_PUT = 'PRODUCT_MODULE_PUT';
export const PRODUCT_MODULE_DEL = 'PRODUCT_MODULE_DEL';
export const PRODUCT_MODULE_ERROR = 'PRODUCT_MODULE_ERROR';
export const PRODUCT_MODULE_LOADING = 'PRODUCT_MODULE_LOADING';

export const CUSTOMER_ALL = 'CUSTOMER_ALL';
export const CUSTOMER_GET = 'CUSTOMER_GET';
export const CUSTOMER_POST = 'CUSTOMER_POST';
export const CUSTOMER_PUT = 'CUSTOMER_PUT';
export const CUSTOMER_DEL = 'CUSTOMER_DEL';
export const CUSTOMER_ERROR = 'CUSTOMER_ERROR';
export const CUSTOMER_LOADING = 'CUSTOMER_LOADING';

export const ADVISOR_ALL = 'ADVISOR_ALL';
export const ADVISOR_GET = 'ADVISOR_GET';
export const ADVISOR_POST = 'ADVISOR_POST';
export const ADVISOR_PUT = 'ADVISOR_PUT';
export const ADVISOR_DEL = 'ADVISOR_DEL';
export const ADVISOR_ERROR = 'ADVISOR_ERROR';
export const ADVISOR_LOADING = 'ADVISOR_LOADING';

export const NOTE_ALL = 'NOTE_ALL';
export const NOTE_GET = 'NOTE_GET';
export const NOTE_POST = 'NOTE_POST';
export const NOTE_PUT = 'NOTE_PUT';
export const NOTE_DEL = 'NOTE_DEL';
export const NOTE_ERROR = 'NOTE_ERROR';
export const NOTE_LOADING = 'NOTE_LOADING';

export const FILE_ALL = 'FILE_ALL';
export const FILE_GET = 'FILE_GET';
export const FILE_POST = 'FILE_POST';
export const FILE_PUT = 'FILE_PUT';
export const FILE_DEL = 'FILE_DEL';
export const FILE_ERROR = 'FILE_ERROR';
export const FILE_LOADING = 'FILE_LOADING';

export const IMAGE_ALL = 'IMAGE_ALL';
export const IMAGE_GET = 'IMAGE_GET';
export const IMAGE_POST = 'IMAGE_POST';
export const IMAGE_PUT = 'IMAGE_PUT';
export const IMAGE_DEL = 'IMAGE_DEL';
export const IMAGE_ERROR = 'IMAGE_ERROR';
export const IMAGE_LOADING = 'IMAGE_LOADING';
