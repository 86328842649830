import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Button,
} from '@mui/material';
import PropTypes from 'prop-types';
import _ from 'lodash';

import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/th';

import { FILE_TYPE } from '../../utils/constants';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

export default function FileTable({
  page,
  size,
  total,
  setPage,
  setSize,
  file,
  handleDeleteFile,
}) {
  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  return (
    <div className="mt-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size={'small'}>
            <TableHead>
              <TableRow className="bg-gray-200">
                <TableCell>
                  <div className="font-bold py-1">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ไฟล์</div>
                </TableCell>{' '}
                <TableCell>
                  <div className="font-bold py-1">รูปแบบ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">อัพโหลด</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(file?.rows) ? (
                _.map(file?.rows, (row, index) => (
                  <TableRow key={row?._id}>
                    <TableCell component="th" scope="row">
                      <div className="py-1">{`${
                        (page - 1) * size + index + 1
                      }`}</div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <a href={row?.url}>
                        <div className="cursor-pointer hover:text-gray-500 hover:underline">
                          {row?.file_name}
                        </div>
                      </a>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {FILE_TYPE[row?.file_type]?.description}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {dayjs(row?.updatedAt).format('D MMM BB HH:mm')}
                    </TableCell>

                    <TableCell component="th" scope="row">
                      <div className="flex flex-wrap gap-1">
                        <Button
                          variant="outlined"
                          color="error"
                          size="small"
                          onClick={() => {
                            handleDeleteFile(row?._id);
                          }}
                        >
                          ลบ
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );
}

FileTable.propTypes = {
  page: PropTypes.number,
  size: PropTypes.number,
  total: PropTypes.number,
  setPage: PropTypes.func,
  setSize: PropTypes.func,
  file: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  handleDeleteFile: PropTypes.func,
};
