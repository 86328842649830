import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Button, Card, CardContent, Box, Tab, Tabs } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import _ from 'lodash';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/th';

import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { ViewTitle } from '../../components/ViewTitle';
import { FileInProduct } from '../../components/SubPage';

import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { Link } from 'react-router-dom';
dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

export default function ProductFile({ title, subtitle }) {
  const dispatch = useDispatch();

  const params = useParams();
  const product = useSelector((state) => state.product);

  const renderTitle = () => (
    <ViewTitle title={`${title} ${product?.name || ''}`} subtitle={subtitle} />
  );

  useEffect(() => {
    dispatch(actions.productGet(params.id));

    return () => {};
  }, [params]);

  const renderData = () => (
    <div>
      <FileInProduct />
    </div>
  );

  if (product?.isLoading || product?.rows) {
    return <Loading />;
  }

  if (product?.isCompleted && !product?.isLoading && !product?.rows) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-between pb-4">
          <BackButton />
        </div>
        {renderData()}
      </div>
    );
  }

  return <Error />;
}

ProductFile.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ProductFile.defaultProps = {
  title: '',
  subtitle: '',
};
