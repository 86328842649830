import {
  PRODUCT_ALL,
  PRODUCT_GET,
  PRODUCT_POST,
  PRODUCT_PUT,
  PRODUCT_DEL,
  PRODUCT_ERROR,
  PRODUCT_LOADING,
} from '../types';

import api from '../../../utils/functions/api';

export const productCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/product`,
      payload,
    );
    dispatch({ type: PRODUCT_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_ERROR });
    throw new Error(error);
  }
};

export const productAll = (params) => async (dispatch) => {
  try {
    const { name = '', size = '', page = 1 } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/product?name=${name}&size=${size}&page=${page}`,
    );
    if (status === 200) {
      dispatch({ type: PRODUCT_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_ERROR });
    throw new Error(error);
  }
};

export const productGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/product/${id}`,
    );
    if (status === 200) {
      dispatch({ type: PRODUCT_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_ERROR });
    throw new Error(error);
  }
};

export const productPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/product/${id}`,
      payload,
    );
    dispatch({ type: PRODUCT_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_ERROR });
    throw new Error(error);
  }
};

export const productDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/product/${id}`,
    );
    dispatch({ type: PRODUCT_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_ERROR });
    throw new Error(error);
  }
};
