import React, { useState, useEffect } from 'react';
import _ from 'lodash';

function Clock() {
  const h24 = true;
  const [hour, setHour] = useState(0);
  const [minute, setMinute] = useState(0);
  const [second, setSecond] = useState(0);
  const [day, setDay] = useState(0);
  const [pm, setPm] = useState(false);

  useEffect(() => {
    const update = () => {
      const date = new Date();
      let hour = date.getHours();
      if (!h24) {
        hour = hour % 12 || 12;
      }
      setHour(hour);
      setMinute(date.getMinutes());
      setSecond(date.getSeconds());
      setDay(date.getDay());
      setPm(date.getHours() >= 12);
    };

    update();

    const interval = setInterval(() => {
      update();
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  return (
    <div className="flex w-32">
      <div className="w-1/3 text-right">{hour}</div>
      <div className="ml-1"> :{_.padStart(minute, 2, 0)} </div>
      <div className="w-1/3 text-left ml-1"> :{_.padStart(second, 2, 0)}</div>
    </div>
  );
}

export default Clock;
