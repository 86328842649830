import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import PropTypes from 'prop-types';
import _ from 'lodash';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/th';

import * as actions from '../../redux/actions';

import { SystemTable } from '../Table';
import { Loading } from '../Loading';
import { Error } from '../Error';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

export default function SystemInProject() {
  const dispatch = useDispatch();

  const params = useParams();
  const system = useSelector((state) => state.system);

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState();

  useEffect(() => {
    dispatch(
      actions.systemAll({
        page,
        size,
        project: params.id,
      }),
    );

    return () => {};
  }, [params, page, size]);

  useEffect(() => {
    if (system?.rows) {
      setTotal(system?.total);
    }

    return () => {};
  }, [system]);

  const handleDeleteSystem = async (id) => {
    const confirm = window.confirm('ยืนยันการลบ');
    if (confirm) {
      await dispatch(actions.systemDelete(id));
      await dispatch(actions.systemAll({ size, page, project: params.id }));
    }
  };

  if (system?.isLoading) {
    return <Loading />;
  }

  if (system?.isCompleted && !system?.isLoading) {
    return (
      <div>
        <SystemTable
          handleDeleteSystem={handleDeleteSystem}
          page={page}
          setPage={setPage}
          setSize={setSize}
          size={size}
          system={system}
          total={total}
        />
      </div>
    );
  }

  return <Error />;
}
