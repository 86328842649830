import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { useParams, useHistory } from 'react-router';
import { Button, Card } from '@mui/material';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { SystemForm } from '../../components/Form';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/th';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

export default function CreateSystem({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const project = useSelector((state) => state.project);
  const droplet = useSelector((state) => state.droplet);
  const product = useSelector((state) => state.product);
  const advisor = useSelector((state) => state.advisor);
  const customer = useSelector((state) => state.customer);
  const dropletProject = useSelector((state) => state.dropletProject);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      docker: [
        {
          containerName: '',
          port: '',
        },
      ],
      user: [
        {
          user: '',
          password: '',
          superuser: false,
        },
      ],
    },
  });

  const {
    fields: dockerFields,
    append: dockerAppend,
    remove: dockerRemove,
  } = useFieldArray({
    control,
    name: 'docker',
  });

  const {
    fields: userFields,
    append: userAppend,
    remove: userRemove,
  } = useFieldArray({
    control,
    name: 'user',
  });

  useEffect(() => {
    dispatch(actions.projectAll({ name: '', size: '', page: 1 }));
    dispatch(actions.dropletAll({ name: '', size: '', page: 1 }));
    dispatch(actions.customerAll({ name: '', size: '', page: 1 }));
    dispatch(actions.advisorAll({ name: '', size: '', page: 1 }));
    dispatch(actions.productAll({ name: '', size: '', page: 1 }));
    dispatch(actions.dropletProjectAll({ name: '', size: '', page: 1 }));
    return () => {};
  }, [id]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data) => {
    try {
      data.docker = getValues('docker');
      data.user = getValues('user');
      data.start_date = new Date(startDate).toISOString().split('T')[0];
      data.end_date = new Date(endDate).toISOString().split('T')[0];
      // // console.log('DATA: ', data);
      dispatch(actions.systemCreate(data));
      alert('สำเร็จ');

      history.goBack();
    } catch (error) {
      alert(error.message);
      console.error(error);
    }
  };

  const renderForm = () => (
    <div>
      <Card className="p-4">
        <form onSubmit={handleSubmit(onSubmit)}>
          <SystemForm
            control={control}
            Controller={Controller}
            errors={errors}
            project={project}
            droplet={droplet}
            dropletProject={dropletProject}
            dockerFields={dockerFields}
            dockerAppend={dockerAppend}
            dockerRemove={dockerRemove}
            userFields={userFields}
            userAppend={userAppend}
            userRemove={userRemove}
            getValues={getValues}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            advisor={advisor}
            customer={customer}
            product={product}
          />
          <div className="flex flex-row justify-end gap-1 mt-2">
            <Button variant="contained" type="submit">
              บันทึก
            </Button>
          </div>
        </form>
      </Card>
    </div>
  );

  return (
    <div>
      {renderTitle()}
      <div className="flex flex-row justify-start pb-4">
        <div>
          <BackButton />
        </div>
      </div>
      {renderForm()}
    </div>
  );
}

CreateSystem.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreateSystem.defaultProps = {
  title: '',
  subtitle: '',
};
