import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Button, Card } from '@mui/material';
import PropTypes from 'prop-types';
import _ from 'lodash';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/th';

import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { ViewTitle } from '../../components/ViewTitle';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { SystemTable } from '../../components/Table';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

export default function DetailAdvisor({ title, subtitle }) {
  const dispatch = useDispatch();
  const params = useParams();
  const advisor = useSelector((state) => state.advisor);
  const system = useSelector((state) => state.system);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);
  useEffect(() => {
    dispatch(actions.advisorGet(params.id));

    return () => {};
  }, [params]);

  useEffect(() => {
    dispatch(
      actions.systemAll({
        name: '',
        size,
        page,
        advisor: params.id,
      }),
    );

    return () => {};
  }, [page, size]);

  useEffect(() => {
    setTotal(system?.total);
    return () => {};
  }, [system]);

  const renderData = () => (
    <div>
      <Card className="p-4">
        <div className="w-full flex flex-row ">
          <div className="w-full lg:w-1/6 my-1">
            <img src={advisor?.image?.url} className="h-40" />
          </div>
          <div className="w-full lg:w-2/3 py-1">
            {' '}
            <h3 className="w-full text-2xl mb-4">{advisor?.name}</h3>
            <p>
              <b>ภาควิชา / สาขาวิชา :</b> {advisor?.department || '-'}
            </p>
            <p>
              <b>คณะ :</b> {advisor?.faculty || '-'}
            </p>
            <p>
              <b>สถานบัน :</b> {advisor?.university || '-'}
            </p>
            <p>
              <b>หมายเลขโทรศัพท์ :</b> {advisor?.tel || '-'}
            </p>
          </div>
        </div>
      </Card>
    </div>
  );

  const handleDeleteSystem = async (id) => {
    const confirm = window.confirm('ยืนยันการลบ');
    if (confirm) {
      await dispatch(actions.systemDelete(id));
      await dispatch(actions.systemAll({ size, page, advisor: params.id }));
    }
  };

  const renderTable = () => (
    <SystemTable
      handleDeleteSystem={handleDeleteSystem}
      page={page}
      setPage={setPage}
      size={size}
      setSize={setSize}
      system={system}
      total={total}
    />
  );

  if (advisor?.isLoading || advisor?.rows) {
    return <Loading />;
  }

  if (advisor?.isCompleted && !advisor?.isLoading && !advisor?.rows) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        {renderData()}
        {renderTable()}
      </div>
    );
  }

  return <Error />;
}

DetailAdvisor.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailAdvisor.defaultProps = {
  title: '',
  subtitle: '',
};
