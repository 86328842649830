import {
  DROPLET_PROJECT_ALL,
  DROPLET_PROJECT_GET,
  DROPLET_PROJECT_POST,
  DROPLET_PROJECT_PUT,
  DROPLET_PROJECT_DEL,
  DROPLET_PROJECT_ERROR,
  DROPLET_PROJECT_LOADING,
} from '../types';

import api from '../../../utils/functions/api';

export const dropletProjectCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: DROPLET_PROJECT_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/droplet-project`,
      payload,
    );
    dispatch({ type: DROPLET_PROJECT_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: DROPLET_PROJECT_ERROR });
    throw new Error(error);
  }
};

export const dropletProjectAll = (params) => async (dispatch) => {
  try {
    const { size = null, page = 1 } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/droplet-project?size=${size}&page=${page}`,
    );
    if (status === 200) {
      dispatch({ type: DROPLET_PROJECT_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: DROPLET_PROJECT_ERROR });
    throw new Error(error);
  }
};

export const dropletProjectGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/droplet-project/${id}`,
    );
    if (status === 200) {
      dispatch({ type: DROPLET_PROJECT_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: DROPLET_PROJECT_ERROR });
    throw new Error(error);
  }
};

export const dropletProjectPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: DROPLET_PROJECT_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/droplet-project/${id}`,
      payload,
    );
    dispatch({ type: DROPLET_PROJECT_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: DROPLET_PROJECT_ERROR });
    throw new Error(error);
  }
};

export const dropletProjectDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: DROPLET_PROJECT_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/droplet-project/${id}`,
    );
    dispatch({ type: DROPLET_PROJECT_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: DROPLET_PROJECT_ERROR });
    throw new Error(error);
  }
};
