import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';
import { Button, Card } from '@mui/material';
import PropTypes from 'prop-types';
import _ from 'lodash';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/th';

import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { ViewTitle } from '../../components/ViewTitle';
import { ProductModuleForm } from '../../components/Form';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

export default function EditProductModule({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const productModule = useSelector((state) => state.productModule);

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {},
  });

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  useEffect(() => {
    dispatch(actions.productModuleGet(params.id));

    return () => {};
  }, [params]);

  const onSubmit = async (data) => {
    try {
      await dispatch(actions.productModulePut(params.id, data));
      dispatch(
        actions.productModuleAll({
          name: '',
          page: 1,
          size: 10,
          product: productModule?.product?._id,
        }),
      );

      history.goBack();
    } catch (error) {
      alert(error.message);
      console.error(error);
    }
  };

  const renderForm = () => (
    <div>
      <Card className="p-4">
        <form onSubmit={handleSubmit(onSubmit)}>
          <ProductModuleForm
            control={control}
            errors={errors}
            productModule={productModule}
          />
          <div className="flex flex-row justify-end gap-1 mt-2">
            <Button variant="contained" type="submit">
              บันทึก
            </Button>
          </div>
        </form>
      </Card>
    </div>
  );

  if (productModule?.isLoading || productModule?.rows) {
    return <Loading />;
  }

  if (
    productModule?.isCompleted &&
    !productModule?.isLoading &&
    !productModule?.rows
  ) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        {renderForm()}
      </div>
    );
  }

  return <Error />;
}

EditProductModule.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditProductModule.defaultProps = {
  title: '',
  subtitle: '',
};
