import { Button, CardContent, Card, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { DonutChart, BarChart } from '../Chart';

function ProductOnDashboardBox({ eachGroup }) {
  const [showMore, setIsShowMore] = useState(false);
  const successTotal = _.sumBy(eachGroup, 'success');
  const totalActive = _.sumBy(eachGroup, 'active');
  const unSuccessTotal = totalActive - successTotal;
  const totalPaid = _.sumBy(eachGroup, 'on_paid');
  const totalCount = _.sumBy(eachGroup, 'amount');
  const totalPositive = _.sumBy(eachGroup, 'positive');
  const negativeButPaid = _.sumBy(eachGroup, 'negativeButPaid');

  const dataOnGroup = _.map(eachGroup, (eachAnalysis, index) => (
    <div className="w-full lg:w-1/3 md:w-1/2" key={index}>
      <div className="rounded-md shadow-md w-full p-4">
        {eachAnalysis?._id ? (
          <Link to={`/monitor/product/detail/${eachAnalysis?._id}`}>
            <div>
              <img src={eachAnalysis?.product?.logo?.url} className="h-12" />
            </div>
            {/* <div className="font-semibold text-sm text-gray-200 hover:text-gray-600">
                  {eachAnalysis?.product?.name || 'ไม่ระบุ'}
                </div> */}
          </Link>
        ) : (
          <div>ไม่ระบุ</div>
        )}
        <div className="my-2">
          <div className="text-base font-semibold">
            <span className="mr-2 rounded-full px-0.5 h-1 bg-blue-200"></span>{' '}
            เปิด {eachAnalysis?.active} ระบบ
          </div>

          <div className="text-sm">
            <span className="mr-2 rounded-full px-0.5 h-1 bg-green-200"></span>{' '}
            Success {eachAnalysis?.success} ระบบ
          </div>

          <div className="text-sm">
            <span className="mr-2 rounded-full px-0.5 h-1 bg-gray-200"></span>{' '}
            ทั้งหมด {eachAnalysis?.amount} ระบบ
          </div>

          <div className="text-sm">
            <span className="mr-2 rounded-full px-0.5 h-1 bg-pink-200"></span>{' '}
            จ่ายเงิน {eachAnalysis?.on_paid} ระบบ
          </div>
        </div>
      </div>
    </div>
  ));
  return (
    <div className="px-2 my-2">
      <div className="rounded-md bg-gray-300 p-2 mb-2">
        <div className="flex flex-wrap justify-between items-center">
          <div className="flex gap-2">
            <div className="text-lg font-semibold ">
              {eachGroup?.[0]?.product?.product_group || 'ไม่ระบุ'}
            </div>
            <div>
              {showMore ? (
                <IconButton
                  size="small"
                  color="inherit"
                  onClick={() => setIsShowMore(false)}
                >
                  <i className="fas text-sm fa-chevron-up" />
                </IconButton>
              ) : (
                <IconButton
                  size="small"
                  color="inherit"
                  onClick={() => setIsShowMore(true)}
                >
                  <i className="fas text-sm fa-chevron-down" />
                </IconButton>
              )}
            </div>
          </div>
          <div className="flex gap-2 text-sm">
            <div>เปิด {totalActive} ระบบ</div>
            <div>สำเร็จ {successTotal} ระบบ</div>
            <div>จ่ายเงิน {totalPaid} ระบบ</div>
          </div>
        </div>
      </div>
      <div>
        {showMore && (
          <Card>
            <CardContent>
              <div className="flex flex-wrap justify-between items-center">
                <div className="w-full lg:w-1/2">
                  <div>เปิดอยู่เทียบกับทั้งหมด</div>
                  <DonutChart
                    series={[totalActive, totalCount - totalActive]}
                    labels={['เปิด', 'ปิด']}
                  />
                </div>{' '}
                <div className="w-full lg:w-1/2">
                  <div>สำเร็จเที่ยบกับทั้งหมด</div>
                  <DonutChart
                    series={[successTotal, unSuccessTotal]}
                    labels={['สำเร็จ', 'ไม่สำเร็จ']}
                  />
                </div>
                <div className="w-full lg:w-1/2">
                  <div>สำเร็จเทียบกับจ่ายเงิน</div>
                  <DonutChart
                    series={[totalPaid, successTotal - totalPaid]}
                    labels={['จ่ายเงิน', 'ไม่จ่ายเงิน']}
                  />
                </div>
                <div className="w-full lg:w-1/2">
                  <div>จ่ายเงินเทียบทั้งหมด</div>
                  <DonutChart
                    series={[totalPaid, totalActive - totalPaid]}
                    labels={['จ่ายเงิน', 'ไม่จ่ายเงิน']}
                  />
                </div>
                <div className="w-full lg:w-1/2">
                  <div>กิจการที่มีแนวโน้มดี กับทั้งหมด</div>
                  <DonutChart
                    series={[totalPositive, totalActive - totalPositive]}
                    labels={['แนวโน้มดี', 'แนวโน้มไม่ดี']}
                  />
                </div>
                <div className="w-full lg:w-1/2">
                  <div>ระบบที่สำเร็จเทียบกับกิจการที่มีแนวโน้มที่ดีทั้งหมด</div>
                  <DonutChart
                    series={[successTotal, totalPositive - successTotal]}
                    labels={['สำเร็จ', 'ไม่สำเร็จ']}
                  />
                </div>
                <div className="w-full lg:w-1/2">
                  <div>
                    ระบบที่จ่ายเงินแต่มีแนวโน้มที่ไม่ดี (มีแนวโน้มไม่จ่ายต่อ)
                  </div>
                  <DonutChart
                    series={[totalPaid - negativeButPaid, negativeButPaid]}
                    labels={['แนวโน้มต่อ', 'แนวโน้มเลิก']}
                  />
                </div>
              </div>
            </CardContent>
          </Card>
        )}
      </div>
      <div className="flex flex-wrap">{dataOnGroup}</div>
    </div>
  );
}

export default ProductOnDashboardBox;
