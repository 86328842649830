import DropletProjectManagement from './DropletProjectManagement';
import DropletManagement from './DropletManagement';
import ProjectManagement from './ProjectManagement';
import SystemManagement from './SystemManagement';
import DropletDashboard from './DropletDashboard';
import EditSystem from './EditSystem';
import EditProject from './EditProject';
import DetailDroplet from './DetailDroplet';
import DetailSystem from './DetailSystem';
import DetailProject from './DetailProject';
import CreateSystem from './CreateSystem';
import CustomerManagement from './CustomerManagement';
import ProductManagement from './ProductManagement';
import CreateProduct from './CreateProduct';
import EditProduct from './EditProduct';
import DetailProduct from './DetailProduct';
import ProductModule from './ProductModule';
import EditProductModule from './EditProductModule';
import EditCustomer from './EditCustomer';
import DetailCustomer from './DetailCustomer';
import AdvisorManagement from './AdvisorManagement';
import EditAdvisor from './EditAdvisor';
import DetailAdvisor from './DetailAdvisor';
import Dashboard from './Dashboard';
import DetailSystemModule from './DetailSystemModule';
import EditSystemModule from './EditSystemModule';
import EditNote from './EditNote';
import ProductFile from './ProductFile';
import EditDroplet from './EditDroplet';

export {
  DropletProjectManagement,
  DropletManagement,
  ProjectManagement,
  SystemManagement,
  DropletDashboard,
  EditSystem,
  EditProject,
  DetailDroplet,
  DetailSystem,
  DetailProject,
  CreateSystem,
  CustomerManagement,
  ProductManagement,
  CreateProduct,
  EditProduct,
  DetailProduct,
  ProductModule,
  EditProductModule,
  EditCustomer,
  DetailCustomer,
  AdvisorManagement,
  EditAdvisor,
  DetailAdvisor,
  Dashboard,
  DetailSystemModule,
  EditSystemModule,
  EditNote,
  ProductFile,
  EditDroplet,
};

export default {
  DropletProjectManagement,
  DropletManagement,
  ProjectManagement,
  SystemManagement,
  DropletDashboard,
  EditSystem,
  EditProject,
  DetailDroplet,
  DetailSystem,
  DetailProject,
  CreateSystem,
  CustomerManagement,
  ProductManagement,
  CreateProduct,
  EditProduct,
  DetailProduct,
  ProductModule,
  EditProductModule,
  EditCustomer,
  DetailCustomer,
  AdvisorManagement,
  EditAdvisor,
  DetailAdvisor,
  Dashboard,
  DetailSystemModule,
  EditSystemModule,
  EditNote,
  ProductFile,
  EditDroplet,
};
