import {
  ADVISOR_ALL,
  ADVISOR_GET,
  ADVISOR_POST,
  ADVISOR_PUT,
  ADVISOR_DEL,
  ADVISOR_ERROR,
  ADVISOR_LOADING,
} from '../../actions/types';

const initialState = {
  droplet: null,
  isLoading: true,
  isCompleted: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADVISOR_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case ADVISOR_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case ADVISOR_POST:
      return { isLoading: false, isCompleted: true };
    case ADVISOR_PUT:
      return { isLoading: false, isCompleted: true };
    case ADVISOR_DEL:
      return { isLoading: false, isCompleted: true };
    case ADVISOR_LOADING:
      return { isLoading: true, isCompleted: true };
    case ADVISOR_ERROR:
      return { isLoading: false, isCompleted: false };
    default:
      return state;
  }
}
