import {
  DROPLET_ALL,
  DROPLET_GET,
  DROPLET_POST,
  DROPLET_PUT,
  DROPLET_DEL,
  DROPLET_ERROR,
  DROPLET_LOADING,
} from '../../actions/types';

const initialState = {
  droplet: null,
  isLoading: true,
  isCompleted: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DROPLET_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case DROPLET_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case DROPLET_POST:
      return { isLoading: false, isCompleted: true };
    case DROPLET_PUT:
      return { isLoading: false, isCompleted: true };
    case DROPLET_DEL:
      return { isLoading: false, isCompleted: true };
    case DROPLET_LOADING:
      return { isLoading: true, isCompleted: true };
    case DROPLET_ERROR:
      return { isLoading: false, isCompleted: false };
    default:
      return state;
  }
}
