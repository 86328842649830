import { Button, TextField } from '@mui/material';

import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import _ from 'lodash';

export function ProductModuleForm({ errors, productModule, control }) {
  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-2">
        <Controller
          name={'name'}
          control={control}
          defaultValue={productModule ? productModule.name : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อ"
              required
              fullWidth
              size={'small'}
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'code'}
          control={control}
          defaultValue={productModule ? productModule.code : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="รหัส"
              fullWidth
              size={'small'}
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <div className="w-full px-1 py-2">
          <Controller
            name={'description'}
            control={control}
            defaultValue={productModule ? productModule.description : ''}
            render={({ field }) => (
              <TextField
                {...field}
                label="รายละเอียด"
                fullWidth
                multiline={true}
                rows={3}
                size={'small'}
              />
            )}
          />
        </div>
      </div>
      <div className="w-full px-1 py-2">ฟีเจอร์</div>
      <div className="w-full px-1 py-2">
        <div className="w-full px-1 py-2">
          <Controller
            name={'feature'}
            control={control}
            defaultValue={productModule ? productModule.feature : ''}
            render={({ field }) => (
              <TextField
                {...field}
                label="ฟีเจอร์"
                fullWidth
                multiline={true}
                rows={5}
                size={'small'}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}

ProductModuleForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.object,
    description: PropTypes.object,
    start_date: PropTypes.object,
    end_date: PropTypes.object,
  }),
  productModule: PropTypes.object,

  control: PropTypes.object,
};

export default ProductModuleForm;
