import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFieldArray } from 'react-hook-form';
import _ from 'lodash';

export function CustomerForm({ errors, customer, control }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'contacts',
  });

  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-2">
        <Controller
          name={'name'}
          control={control}
          defaultValue={customer ? customer.name : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อ"
              required
              fullWidth
              size={'small'}
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'address'}
          control={control}
          defaultValue={customer ? customer.address : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="ที่อยู่"
              fullWidth
              multiline
              rows={2}
              size={'small'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'tax_id'}
          control={control}
          defaultValue={customer ? customer.tax_id : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="หมายเลขประจำตัวผู้เสียภาษี"
              fullWidth
              size={'small'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'province'}
          control={control}
          defaultValue={customer ? customer.province : ''}
          render={({ field }) => (
            <TextField {...field} label="จังหวัด" fullWidth size={'small'} />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'description'}
          control={control}
          defaultValue={customer ? customer.description : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="รายละเอียด"
              fullWidth
              multiline
              rows={2}
              size={'small'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'buisness_type'}
          control={control}
          defaultValue={customer ? customer.business_type : ''}
          render={({ field }) => (
            <FormControl fullWidth size="small">
              <InputLabel>ลักษณะธุรกิจ</InputLabel>
              <Select fullWidth size="small" {...field}>
                <MenuItem value="บริษัทจำกัด">บริษัทจำกัด</MenuItem>
                <MenuItem value="บริษัทมหาชนจำกัด">บริษัทมหาชนจำกัด</MenuItem>
                <MenuItem value="ห้างหุ้นส่วนจำกัด">ห้างหุ้นส่วนจำกัด</MenuItem>
                <MenuItem value="ห้างหุ้นส่วนสามัญนิติบุคคล">
                  ห้างหุ้นส่วนสามัญนิติบุคคล
                </MenuItem>
                <MenuItem value="พาณิชย์">พาณิชย์</MenuItem>
                <MenuItem value="วิสาหกิจชุมชน">วิสาหกิจชุมชน</MenuItem>
                <MenuItem value="อื่น ๆ">อื่น ๆ</MenuItem>
              </Select>
            </FormControl>
          )}
        />
      </div>
      <div className="w-full px-1 py-2">ผู้ติดต่อ</div>
      <div className="w-full px-1 py-2">
        {_.map(fields, (eachField, index) => (
          <div key={eachField.id} className="flex flex-wrap">
            <div className="w-full md:w-1/4 px-1 py-2">
              <Controller
                name={`contacts[${index}].name`}
                control={control}
                defaultValue={eachField?.name}
                render={({ field }) => (
                  <TextField {...field} label="ชื่อ" fullWidth size={'small'} />
                )}
              />
            </div>
            <div className="w-full md:w-1/4 px-1 py-2">
              <Controller
                name={`contacts[${index}].position`}
                control={control}
                defaultValue={eachField?.position}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="ตำแหน่ง"
                    fullWidth
                    size={'small'}
                  />
                )}
              />
            </div>
            <div className="w-full md:w-1/4 px-1 py-2">
              <Controller
                name={`contacts[${index}].tel`}
                control={control}
                defaultValue={eachField?.tel}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="หมายเลขโทรศัพท์"
                    fullWidth
                    size={'small'}
                  />
                )}
              />
            </div>
            <div className="w-full md:w-1/6 px-1 py-2">
              <Button
                size="small"
                color="error"
                variant="contained"
                onClick={() => remove(index)}
              >
                ลบ
              </Button>
            </div>
          </div>
        ))}
        <div className="w-full  px-1 py-2">
          <Button size="small" variant="contained" onClick={() => append({})}>
            เพิ่ม
          </Button>
        </div>
      </div>
    </div>
  );
}

CustomerForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.object,
    description: PropTypes.object,
    start_date: PropTypes.object,
    end_date: PropTypes.object,
  }),
  customer: PropTypes.object,
  control: PropTypes.object,
};

export default CustomerForm;
