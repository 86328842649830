import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { Button, Card, CardContent } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/th';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

export default function DetailSystemModule({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const system = useSelector((state) => state.system);

  useEffect(() => {
    dispatch(actions.systemGet(id));
    return () => {};
  }, [id]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderDetail = () => (
    <div className="mt-2">
      <Card>
        <CardContent>
          {_.map(system?.modules, (eachModule, index) => (
            <div key={index} className="mx-2">
              <div className="font-semibold font-system text-lg">
                {eachModule?.name}
              </div>
              <p className="m-2 px-2">
                <div className="md-content font-system">
                  <ReactMarkdown children={eachModule?.feature || '-'} />
                </div>
              </p>
            </div>
          ))}
        </CardContent>
      </Card>
    </div>
  );

  if (system.isLoading || system.rows) {
    return <Loading />;
  }

  if (!system.isLoading && system.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-between pb-4">
          <BackButton />
          <Link to={`/monitor/system/module/edit/${id}`}>
            <Button variant="contained" color="warning">
              แก้ไข
            </Button>
          </Link>
        </div>
        {renderDetail()}
      </div>
    );
  }

  return <Error message={system?.message} />;
}

DetailSystemModule.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailSystemModule.defaultProps = {
  title: '',
  subtitle: '',
};
