import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Button,
} from '@mui/material';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as actions from '../../redux/actions';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import copy from 'copy-to-clipboard';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/th';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);
dayjs.extend(relativeTime);

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SystemTable({
  page,
  size,
  total,
  setPage,
  setSize,
  system,
  handleDeleteSystem,
  handleEditSystem,
}) {
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleOpenSnackbar = () => {
    // console.log('openSnackbar');
    setOpenSnackbar(true);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleCopyToClipboard = (data) => {
    const userList = _.reduce(
      data.user,
      function (result, eachUser) {
        if (!eachUser.superuser) {
          return (
            result +
            `\nusername : ${eachUser.user}\npassword : ${eachUser.password}`
          );
        }
        return result;
      },
      '',
    );
    const text = `${data?.name}\n${data?.url}${userList}`;
    copy(text);
    handleOpenSnackbar();
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  return (
    <div className="mt-2">
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <Alert
          onClose={handleCloseSnackBar}
          severity="info"
          sx={{ width: '100%' }}
        >
          คัดลอกข้อมูลระบบ
        </Alert>
      </Snackbar>
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size={'small'}>
            <colgroup>
              <col width="5%" />
              <col width="15%" />
              <col width="10%" />
              <col width="15%" />

              <col width="15%" />
              <col width="10%" />
              <col width="5%" />
              <col width="15%" />
            </colgroup>
            <TableHead>
              <TableRow className="bg-gray-200">
                <TableCell>
                  <div className="font-bold py-1">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ชื่อ</div>
                </TableCell>{' '}
                <TableCell>
                  <div className="font-bold py-1">Product</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">โครงการ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">Techical Detail</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ที่ปรึกษา</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">หมายเหตุ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(system?.rows) ? (
                _.map(system?.rows, (row, index) => (
                  <TableRow key={row?._id}>
                    <TableCell component="th" scope="row">
                      <div className="py-1">{`${
                        (page - 1) * size + index + 1
                      }`}</div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div>
                        <Link to={`/monitor/system/detail/${row?._id}`}>
                          <b className="text-base">{`${row?.name || '-'}`}</b>{' '}
                          {row?.status === true ? (
                            <i
                              className="fa fa-check-circle green-color"
                              style={{ color: 'green' }}
                            ></i>
                          ) : (
                            <i
                              className="fa fa-times-circle green-color"
                              style={{ color: 'red' }}
                            ></i>
                          )}
                          {row?.success === true ? (
                            <i className="fa fa-trophy mx-1 text-yellow-500"></i>
                          ) : (
                            <span></span>
                          )}
                          {row?.on_paid === true ? (
                            <i className="fa fa-money-bill mx-1 text-gray-400"></i>
                          ) : (
                            <span></span>
                          )}
                          {row?.aptitude === true ? (
                            <i className="fa fa-smile text-green-400 mx-1"></i>
                          ) : (
                            <i className="fa fa-angry text-red-400 mx-1"></i>
                          )}
                        </Link>

                        <p className="text-gray-500">{`${
                          row?.description || ''
                        }`}</p>
                        <a
                          className=" text-blue-600 hover:text-blue-400 hover:underline cursor-pointer"
                          onClick={() => window.open(`${row?.url}`)}
                        >{`${row?.url || ''}`}</a>

                        <br />
                        {row?.tag ? (
                          <p className="my-2 inline-flex bg-pink-600 text-white rounded-full h-6 px-3 justify-center items-center">{`${
                            row?.tag || ''
                          }`}</p>
                        ) : (
                          <p></p>
                        )}

                        {row?.version ? (
                          <p className="text-xs text-gray-500">
                            Version : {row?.version || '-'}
                          </p>
                        ) : (
                          <p></p>
                        )}
                        {row?.start_date ? (
                          <p className="text-xs text-gray-500">
                            {dayjs(row?.start_date)
                              .locale('th')
                              .format('DD MMM BB')}{' '}
                            -{' '}
                            {dayjs(row?.end_date)
                              .locale('th')
                              .format('DD MMM BB')}
                          </p>
                        ) : (
                          <p></p>
                        )}
                        <div className="text-xs">
                          {dayjs().to(dayjs(row?.end_date))}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Link to={`/monitor/product/detail/${row?.product?._id}`}>
                        <img
                          src={row?.product?.logo?.url}
                          alt={row?.product?.name}
                          className="w-24"
                        />
                      </Link>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Link to={`/monitor/project/detail/${row?.project?._id}`}>
                        <p className="text-sm hover:text-gray-400 hover:underline">
                          {`${row?.project?.name || '-'}`}
                        </p>
                      </Link>
                      <p className="text-xs text-gray-500">{`${
                        row?.project?.description || ''
                      }`}</p>

                      {row?.customer ? (
                        <Link
                          className="my-2"
                          to={`/monitor/customer/detail/${row?.customer?._id}`}
                        >
                          {row?.customer?.name}
                        </Link>
                      ) : (
                        <div></div>
                      )}

                      {dayjs().isAfter(dayjs(row?.end_date)) && (
                        <div className="text-yellow-600 font-semibold text-lg">
                          เกินเวลาการให้บริการ
                        </div>
                      )}
                    </TableCell>

                    <TableCell component="th" scope="row">
                      <p className="py-1 text-xs">
                        <b>Droplet</b>{' '}
                        <Link
                          to={`/monitor/droplet/detail/${row?.droplet?._id}`}
                          className="text-gray-500 hover:text-gray-800 underline "
                        >
                          {`${row?.droplet?.name || '-'}`}
                        </Link>
                      </p>
                      {/* <div className="py-1 text-xs">
                        <b>Container</b>{' '}
                        {_.map(row?.docker, (_docker, _index) => {
                          return (
                            <div key={_index}>
                              {_index + 1}. {_docker?.containerName}{' '}
                              <b>{_docker?.port}</b>
                            </div>
                          );
                        })}
                      </div> */}

                      <div className="py-1 text-xs">
                        <b>Database</b> {`${row?.database?.name || '-'}`}
                      </div>
                      {row?.mqtt?.topic && (
                        <div className="py-1 text-xs">
                          <b>MQTT</b> {`${row?.mqtt?.topic || '-'}`}
                        </div>
                      )}
                      {row?.portainer_url && (
                        <div>
                          <a
                            href={row?.portainer_url}
                            className="text-blue-500 hover:text-blue-700"
                            target="_blank"
                          >
                            <i className="fab fa-docker" /> Portainer
                          </a>
                        </div>
                      )}
                    </TableCell>

                    <TableCell component="th" scope="row">
                      <div className="py-1">
                        {' '}
                        {row?.advisor && (
                          <Link
                            to={`/monitor/advisor/detail/${row?.advisor?._id}`}
                          >
                            <img
                              src={row?.advisor?.image?.url}
                              className="rounded-full object-cover h-16 w-16 object-center "
                            />
                            <div className="my-1">{row?.advisor?.name}</div>
                          </Link>
                        )}
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="py-1">{`${row?.remark || ''}`}</div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="flex flex-wrap gap-1">
                        <Link to={'/monitor/system/detail/' + row._id}>
                          <Button variant="outlined" color="info" size="small">
                            รายละเอียด
                          </Button>
                        </Link>
                        <Link to={'/monitor/system/edit/' + row._id}>
                          <Button
                            variant="outlined"
                            color="warning"
                            size="small"
                          >
                            แก้ไข
                          </Button>
                        </Link>
                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          onClick={() => {
                            handleCopyToClipboard(row);
                          }}
                        >
                          คัดลอก
                        </Button>
                        <Button
                          variant="outlined"
                          color="error"
                          size="small"
                          onClick={() => {
                            handleDeleteSystem(row?._id);
                          }}
                        >
                          ลบ
                        </Button>
                      </div>
                      <div className="flex flex-wrap gap-1 my-2">
                        {row?.status ? (
                          <Button
                            variant="contained"
                            color="error"
                            size="small"
                            onClick={() => {
                              handleEditSystem(row?._id, {
                                status: false,
                              });
                            }}
                          >
                            <i className="fas fa-power-off text-base"></i>
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            color="success"
                            size="small"
                            onClick={() => {
                              handleEditSystem(row?._id, {
                                status: true,
                              });
                            }}
                          >
                            <i className="fas fa-power-off text-base"></i>
                          </Button>
                        )}{' '}
                        <Button
                          variant="contained"
                          color="success"
                          size="small"
                          onClick={() => {
                            handleEditSystem(row?._id, {
                              aptitude: true,
                            });
                          }}
                        >
                          <i className="fas fa-smile text-base"></i>
                        </Button>
                        <Button
                          variant="contained"
                          color="warning"
                          size="small"
                          onClick={() => {
                            handleEditSystem(row?._id, {
                              aptitude: false,
                            });
                          }}
                        >
                          <i className="fas fa-angry text-base"></i>
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );
}

SystemTable.propTypes = {
  page: PropTypes.number,
  size: PropTypes.number,
  total: PropTypes.number,
  setPage: PropTypes.func,
  setSize: PropTypes.func,
  system: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  handleDeleteSystem: PropTypes.func,
};
