import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import dayjs from 'dayjs';
import { NOTE_TYPE } from '../../utils/constants';
import { Button, Chip } from '@mui/material';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

function CommentBox({
  totalSize,
  size,
  comment,
  setSize,
  handleDelete,
  displaySystem = false,
}) {
  if (_.isEmpty(comment?.rows)) {
    return <div className="p-4 text-center">ยังไม่มีบันทึก</div>;
  }
  return (
    <div>
      {_.map(comment?.rows, (eachComment, index) => (
        <div key={index} className="my-2 rounded-lg shadow-sm">
          <div className="flex w-full justify-between px-4 py-1 bg-gray-200 rounded-lg">
            <div className="text-xs self-center">
              วันที่ {dayjs(eachComment?.updatedAt).format('D MMM BBBB')}
              <Chip
                label={NOTE_TYPE[eachComment?.message_type]?.description}
                size="small"
                className="ml-2"
              />
              {displaySystem && eachComment?.system && (
                <Link to={`/monitor/system/detail/${eachComment?.system?._id}`}>
                  <Chip
                    label={eachComment?.system?.name}
                    size="small"
                    color="secondary"
                    className="ml-2"
                    clickable
                  />
                </Link>
              )}
            </div>
            <div className="flex gap-2">
              <Link to={`/monitor/note/edit/${eachComment?._id}`}>
                <Button size="small" variant="outlined" color="warning">
                  แก้ไข
                </Button>
              </Link>
              <div>
                <Button
                  size="small"
                  variant="outlined"
                  color="error"
                  onClick={() => handleDelete(eachComment?._id)}
                >
                  ลบ
                </Button>
              </div>
            </div>
          </div>
          <div className="font-system text-sm p-4 md-content">
            <ReactMarkdown children={eachComment?.message} />
          </div>
          <div className="flex gap-2 p-2 flex-wrap">
            {_.map(eachComment?.populate_images, (eachImage, imageIndex) => (
              <div key={imageIndex}>
                <a href={eachImage?.url} target="_blank">
                  <img src={eachImage?.url} className="h-48" />
                </a>
              </div>
            ))}{' '}
          </div>
        </div>
      ))}
      <div className="text-sm flex justify-center">
        <div className="self-center">
          แสดง {size < totalSize ? size : totalSize} จาก {totalSize}
        </div>{' '}
        {size < totalSize && (
          <div className="mx-2">
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                if (size + 5 < totalSize) {
                  setSize(size + 5);
                } else {
                  setSize(totalSize);
                }
              }}
            >
              แสดงเพิ่ม
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

CommentBox.propTypes = {
  totalSize: PropTypes.number,
  size: PropTypes.number,
  comment: PropTypes.shape({
    rows: PropTypes.object,
  }),
  setSize: PropTypes.func,
  handleDelete: PropTypes.func,
  displaySystem: PropTypes.bool,
};

export default CommentBox;
