import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Card, CardContent } from '@mui/material';
import PropTypes from 'prop-types';
import _, { each } from 'lodash';
import * as actions from '../../redux/actions';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ProductOnDashboardBox } from '../../components/Box';
import { BarChart, DonutChart } from '../../components/Chart';
import { ViewTitle } from '../../components/ViewTitle';

import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/th';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

export default function Dashboard({ title, subtitle }) {
  const dispatch = useDispatch();
  const system = useSelector((state) => state.system);
  const project = useSelector((state) => state.project);
  const [showAll, setIsShowAll] = useState(false);

  useEffect(() => {
    dispatch(
      actions.systemAll({
        size: 10,
        page: 1,
        analyzed: true,
      }),
    );
    dispatch(actions.projectAll({ size: 500, page: 1, activated: true }));
    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const calculateStat = (key) =>
    _.map(
      _.values(_.groupBy(system?.analysis, 'product.product_group')),
      (each) => ({
        sum: _.sum(
          _.map(each, (eachInSystem) => {
            return eachInSystem?.[key];
          }),
        ),
        product_group: each?.[0]?.product?.product_group,
      }),
    );

  const systemLabel = _.map(
    calculateStat('success'),
    (item) => item.product_group || 'อื่นๆ',
  );

  const successAmount = _.map(
    calculateStat('success'),
    (item) => item.sum || 0,
  );

  const paidAmount = _.map(calculateStat('on_paid'), (item) => item.sum || 0);
  const positiveAmount = _.map(
    calculateStat('positive'),
    (item) => item.sum || 0,
  );

  const activeAmount = _.map(calculateStat('active'), (item) => item.sum || 0);

  if (system.isLoading) {
    return <Loading />;
  }

  if (!system.isLoading && system.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="my-2">
          <Card>
            <CardContent>
              <div className="mb-3 font-semibold flex">
                สรุปข้อมูลทั้งหมด
                <div className="mx-2">
                  <Button
                    variant="contained"
                    color="inherit"
                    size="small"
                    onClick={() => setIsShowAll(!showAll)}
                  >
                    {showAll ? '- Less' : '+ More'}
                  </Button>
                </div>
              </div>
              <div className="flex flex-wrap">
                <div className="w-full md:w-2/6 border-l-4 pl-2 ">
                  <b>ระบบทั้งหมด</b>
                  <div className="flex gap-1">
                    <div className="text-2xl">{system?.total}</div>
                    <div className="self-end">ระบบ</div>
                  </div>
                </div>
                <div className="w-full md:w-1/6 border-l-4 pl-2 border-blue-500">
                  <b>ระบบที่เปิดอยู่</b>
                  <div className="flex gap-1">
                    <div className="text-2xl">{system?.activated || 0}</div>
                    <div className="self-end">ระบบ</div>
                  </div>
                </div>
                <div className="w-full md:w-1/6 border-l-4 pl-2 border-green-500">
                  <b>ระบบที่ Success</b>
                  <div className="flex gap-1">
                    <div className="text-2xl">{system?.success || 0}</div>
                    <div className="self-end">ระบบ</div>
                  </div>
                </div>{' '}
                <div className="w-full md:w-1/6 border-l-4 pl-2 border-green-400">
                  <b>แนวโน้มที่ดี</b>
                  <div className="flex gap-1">
                    <div className="text-2xl">{system?.positive || 0}</div>
                    <div className="self-end">ระบบ</div>
                  </div>
                </div>
                <div className="w-full md:w-1/6 border-l-4 pl-2 border-yellow-500">
                  <b>ชำระเงิน</b>
                  <div className="flex gap-1">
                    <div className="text-2xl">{system?.onPaid || 0}</div>
                    <div className="self-end">ระบบ</div>
                  </div>
                </div>
                {showAll && <div className="w-full md:w-2/6"></div>}
                {showAll && (
                  <div className="w-full md:w-1/6 border-l-4 my-1 pl-2 border-red-500">
                    <b>ปิดระบบ</b>
                    <div className="flex gap-1">
                      <div className="text-2xl">
                        {system?.total - system?.activated || 0}
                      </div>
                      <div className="self-end">ระบบ</div>
                    </div>
                  </div>
                )}
                {showAll && (
                  <div className="w-full md:w-1/6 border-l-4 my-1 pl-2 border-red-400">
                    <b>แนวโน้มที่ไม่ดี</b>
                    <div className="flex gap-1">
                      <div className="text-2xl">{system?.negative || 0}</div>
                      <div className="self-end">ระบบ</div>
                    </div>
                  </div>
                )}
              </div>{' '}
              {showAll && (
                <div className="flex flex-wrap w-full my-2">
                  <div className="w-full lg:w-1/3 ">
                    <div className="text-center my-2">
                      สถานะของระบบเทียบกับระบบที่เปิดอยู่
                    </div>
                    <div className="flex justify-center">
                      {' '}
                      <BarChart
                        colors={['#008FFB', '#FF4560']}
                        labels={[
                          'การชำระเงิน',
                          'สำเร็จ',
                          'แนวโน้มที่ดี',
                          'เปิดใช้งานทั้งหมด',
                        ]}
                        width={500}
                        series={[
                          {
                            name: 'สำเร็จ',
                            data: [
                              system?.onPaid || 0,
                              system?.success || 0,
                              system?.positive || 0,
                              system?.activated || 0,
                            ],
                          },
                          {
                            name: 'ไม่สำเร็จ',
                            data: [
                              system?.activated - system?.onPaid || 0,
                              system?.activated - system?.success || 0,
                              system?.activated - system?.positive || 0,
                              0,
                            ],
                          },
                        ]}
                        anathorChartOptions={{
                          stacked: true,
                          stackType: '100%',
                          dataLabels: {
                            formatter: function (val, opt) {
                              return val.toFixed(2) + '%';
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-2/3">
                    <div className="text-center my-2">
                      สถานะของระบบเทียบกับระบบที่เปิดอยู่
                    </div>
                    <div className="flex justify-center">
                      <DonutChart
                        labels={systemLabel}
                        series={activeAmount}
                        useVariousColors
                        width={500}
                      />
                    </div>
                  </div>
                  <div className="w-full flex flex-wrap justify-center">
                    <div className="w-full text-center">
                      สถานะของระบบเทียบกับระบบที่เปิดอยู่
                    </div>
                    <BarChart
                      labels={systemLabel}
                      width={1000}
                      series={[
                        {
                          name: 'สำเร็จ',
                          data: successAmount,
                        },
                        {
                          name: 'ชำระเงิน',
                          data: paidAmount,
                        },
                        {
                          name: 'เปิดอยู่',
                          data: activeAmount,
                        },
                      ]}
                      horizontal={false}
                      anathorChartOptions={{}}
                    />
                  </div>
                </div>
              )}{' '}
              <div className="my-3 font-semibold">รายการระบบ</div>
              <div className="grid lg:grid-cols-2">
                {_.map(
                  _.orderBy(
                    _.groupBy(system?.analysis, 'product.product_group'),
                    'length',
                    'desc',
                  ),
                  (eachGroup, key) => (
                    <ProductOnDashboardBox eachGroup={eachGroup} key={key} />
                  ),
                )}
              </div>
              <div className="my-3 font-semibold">โครงการ</div>
              <div className="grid md:grid-cols-3 lg:grid-cols-4">
                {_.map(project?.rows, (eachProject, index) => (
                  <div className="px-2" key={index}>
                    <div className="rounded-md shadow-md w-full p-4">
                      <Link to={`/monitor/project/detail/${eachProject?._id}`}>
                        <div className="font-semibold hover:text-gray-500">
                          {eachProject?.name}
                        </div>
                      </Link>
                      <div className="font-semibold my-2">
                        จำนวน {_.size(eachProject?.systems)} ระบบ
                      </div>
                      <div className="text-sm">{eachProject?.description}</div>
                    </div>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }

  return <Error message={system?.message} />;
}

Dashboard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Dashboard.defaultProps = {
  title: '',
  subtitle: '',
};
