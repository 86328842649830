import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { Auth } from './layouts';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import configureStore from './redux/configureStore';
import { Monitor } from './layouts';
import PrivateRoute from './contexts/PrivateRoute';
import AuthProvider from './contexts/AuthContext';
import theme from './theme';
import { NotFound } from './components/Error';

import '@fortawesome/fontawesome-free/css/all.min.css';
import './assets/styles/index.css';

const store = configureStore();

ReactDOM.render(
  <Router>
    <Provider store={store}>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <div className="bg-gray-100">
            <Switch>
              <Route path="/auth" component={Auth} />
              <PrivateRoute>
                <Route path="/" component={Monitor} />
              </PrivateRoute>
              <Redirect from="*" to="/auth" />
            </Switch>
          </div>
        </ThemeProvider>
      </AuthProvider>
    </Provider>
  </Router>,
  document.getElementById('root'),
);
