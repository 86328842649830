import { TextField } from '@mui/material';

import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

export function ProductForm({ errors, product, control }) {
  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-2">
        <Controller
          name={'name'}
          control={control}
          defaultValue={product ? product.name : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อ"
              required
              fullWidth
              size={'small'}
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'full_title'}
          control={control}
          defaultValue={product ? product.full_title : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อเต็ม"
              fullWidth
              size={'small'}
              helperText={errors.full_title && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'product_group'}
          control={control}
          defaultValue={product ? product.product_group : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="กลุ่มของโปรดักส์"
              fullWidth
              size={'small'}
              helperText={errors.product_group && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'main_concept'}
          control={control}
          defaultValue={product ? product.main_concept : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="คอนเซปต์หลัก"
              fullWidth
              multiline={true}
              rows={3}
              size={'small'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'description'}
          control={control}
          defaultValue={product ? product.description : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="คำอธิบาย"
              fullWidth
              multiline={true}
              rows={3}
              size={'small'}
            />
          )}
        />
      </div>
    </div>
  );
}

ProductForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.object,
    description: PropTypes.object,
    start_date: PropTypes.object,
    end_date: PropTypes.object,
  }),
  product: PropTypes.object,

  control: PropTypes.object,
};

export default ProductForm;
