export const NOTE_TYPE = {
  REQUIREMENT: {
    status_code: 'REQUIREMENT',
    description: 'Requirement',
  },
  PROBLEM: {
    status_code: 'PROBLEM',
    description: 'ปัญหา/อุปสรรค',
  },
  OPPOTUNITY: {
    status_code: 'OPPOTUNITY',
    description: 'โอกาส',
  },
  PROGRESS: {
    status_code: 'PROGRESS',
    description: 'ความก้าวหน้า',
  },
  NOTES: {
    status_code: 'NOTES',
    description: 'โน้ต',
  },
};

export default NOTE_TYPE;
