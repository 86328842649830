import { combineReducers } from 'redux';

// Common
import UserReducers from './common/UserReducers';
import MeReducers from './common/MeReducers';

// feature
import RoleTypesReducer from './features/RoleTypesReducer';
import EmployeeReducer from './features/EmployeeReducer';
import DropletProjectReducer from './features/DropletProjectReducer';
import DropletReducer from './features/DropletReducer';
import ProjectReducer from './features/ProjectReducer';
import SystemReducer from './features/SystemReducer';
import AdvisorReducer from './features/AdvisorReducer';
import CustomerReducer from './features/CustomerReducer';
import ProductModuleReducer from './features/ProductModuleReducer';
import ProductReducer from './features/ProductReducer';
import NoteReducer from './features/NoteReducer';
import FileReducer from './features/FileReducer';

const rootRuducer = combineReducers({
  me: MeReducers,
  user: UserReducers,
  roletype: RoleTypesReducer,
  employee: EmployeeReducer,
  dropletProject: DropletProjectReducer,
  droplet: DropletReducer,
  project: ProjectReducer,
  system: SystemReducer,
  advisor: AdvisorReducer,
  customer: CustomerReducer,
  product: ProductReducer,
  productModule: ProductModuleReducer,
  note: NoteReducer,
  file: FileReducer,
});

export default rootRuducer;
