import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { useParams, useHistory } from 'react-router';
import { Button, Card } from '@mui/material';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { ProjectForm } from '../../components/Form';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/th';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

export default function EditProject({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const project = useSelector((state) => state.project);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm();

  useEffect(() => {
    dispatch(actions.projectGet(id));
    return () => {};
  }, [id]);

  useEffect(() => {
    setStartDate(project?.start_date);
    setEndDate(project?.end_date);
    return () => {};
  }, [project]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data) => {
    try {
      console.log('data', data);
      data.start_date = new Date(startDate).toISOString().split('T')[0];
      data.end_date = new Date(endDate).toISOString().split('T')[0];
      dispatch(actions.projectPut(id, data));
      alert('สำเร็จ');
      await dispatch(actions.projectAll({ name: '', size: 10, page: 1 }));
      history.goBack();
    } catch (error) {
      alert(error.message);
      console.error(error);
    }
  };

  const renderForm = () => (
    <div>
      <Card className="p-4">
        <form onSubmit={handleSubmit(onSubmit)}>
          <ProjectForm
            control={control}
            Controller={Controller}
            errors={errors}
            project={project}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
          <div className="flex flex-row justify-end gap-1 mt-2">
            <Button variant="contained" type="submit">
              บันทึก
            </Button>
          </div>
        </form>
      </Card>
    </div>
  );

  if (project.isLoading || project.rows) {
    return <Loading />;
  }

  if (!project.isLoading && project.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        {renderForm()}
      </div>
    );
  }

  return <Error message={project?.message} />;
}

EditProject.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditProject.defaultProps = {
  title: '',
  subtitle: '',
};
