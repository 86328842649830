import { FormControlLabel, Switch, TextField } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import thLocale from 'date-fns/locale/th';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import React from 'react';
import PropTypes from 'prop-types';

export function ProjectForm({
  errors,
  project,
  Controller,
  control,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) {
  return (
    <div className="flex flex-row flex-wrap">
      <div className="flex flex-wrap w-full px-1 py-2">
        <div className="w-1/2">
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={thLocale}>
            <DatePicker
              label="วันที่เริ่ม"
              value={startDate}
              onChange={(newValue) => {
                setStartDate(newValue);
              }}
              renderInput={(params) => (
                <TextField fullWidth {...params} required />
              )}
            />
          </LocalizationProvider>
        </div>
        <div className="w-1/2">
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={thLocale}>
            <DatePicker
              label="วันที่สิ้นสุด"
              value={endDate}
              onChange={(newValue) => {
                setEndDate(newValue);
              }}
              renderInput={(params) => (
                <TextField fullWidth {...params} required />
              )}
            />
          </LocalizationProvider>
        </div>
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'name'}
          control={control}
          defaultValue={project ? project.name : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อ"
              required
              fullWidth
              size={'small'}
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'description'}
          control={control}
          defaultValue={project ? project.description : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="คำอธิบาย"
              fullWidth
              multiline={true}
              rows={3}
              size={'small'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'activated'}
          control={control}
          defaultValue={project ? project.activated : ''}
          render={({ field }) => (
            <FormControlLabel
              label="สถานะดำเนินการ"
              control={<Switch {...field} checked={field.value} />}
            />
          )}
        />
      </div>
    </div>
  );
}

ProjectForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.object,
    description: PropTypes.object,
    start_date: PropTypes.object,
    end_date: PropTypes.object,
  }),
  project: PropTypes.object,
  Controller: PropTypes.func,
  control: PropTypes.object,
};

ProjectForm.defaultProps = {
  vehicletype: null,
};

export default ProjectForm;
