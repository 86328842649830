import {
  PRODUCT_MODULE_ALL,
  PRODUCT_MODULE_GET,
  PRODUCT_MODULE_POST,
  PRODUCT_MODULE_PUT,
  PRODUCT_MODULE_DEL,
  PRODUCT_MODULE_ERROR,
  PRODUCT_MODULE_LOADING,
} from '../types';

import api from '../../../utils/functions/api';

export const productModuleCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_MODULE_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/product-module`,
      payload,
    );
    dispatch({ type: PRODUCT_MODULE_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_MODULE_ERROR });
    throw new Error(error);
  }
};

export const productModuleAll = ({
  name = '',
  size = '',
  page = 1,
  product = '',
}) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/product-module?name=${name}&size=${size}&page=${page}&product=${product}`,
    );
    if (status === 200) {
      dispatch({ type: PRODUCT_MODULE_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_MODULE_ERROR });
    throw new Error(error);
  }
};

export const productModuleGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/product-module/${id}`,
    );
    if (status === 200) {
      dispatch({ type: PRODUCT_MODULE_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_MODULE_ERROR });
    throw new Error(error);
  }
};

export const productModulePut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_MODULE_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/product-module/${id}`,
      payload,
    );
    dispatch({ type: PRODUCT_MODULE_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_MODULE_ERROR });
    throw new Error(error);
  }
};

export const productModuleDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_MODULE_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/product-module/${id}`,
    );
    dispatch({ type: PRODUCT_MODULE_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_MODULE_ERROR });
    throw new Error(error);
  }
};
