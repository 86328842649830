import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Button,
  Card,
  TextField,
  InputAdornment,
  Modal,
  Fade,
  Backdrop,
} from '@mui/material';

import PropTypes from 'prop-types';
import _ from 'lodash';
import * as actions from '../../redux/actions';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { ProjectForm } from '../../components/Form';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/th';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

export default function ProductManagement({ title, subtitle }) {
  const dispatch = useDispatch();

  const product = useSelector((state) => state.product);

  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [total, setTotal] = useState(undefined);

  useEffect(() => {
    dispatch(actions.productAll({ name, size, page }));
    return () => {};
  }, [name, size, page]);

  useEffect(() => {
    setTotal(product?.total);
    return () => {};
  }, [product]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleDeleteProduct = async (id) => {
    const confirm = window.confirm('ยืนยันการลบ');
    if (confirm) {
      await dispatch(actions.productDelete(id));
      await dispatch(actions.productAll({ size, page }));
    }
  };

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </Card>
  );

  const renderProduct = () => (
    <div className="mt-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size={'small'}>
            <colgroup>
              <col width="5%" />
              <col width="10%" />
              <col width="10%" />
              <col width="10%" />
              <col width="15%" />
              <col width="15%" />
            </colgroup>
            <TableHead>
              <TableRow className="bg-gray-200">
                <TableCell>
                  <div className="font-bold py-1">ลำดับที่</div>
                </TableCell>

                <TableCell>
                  <div className="font-bold py-1">โลโก้</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ชื่อ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">กลุ่ม</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ชื่อเต็ม</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableHead>
              {!_.isEmpty(product?.rows) ? (
                _.map(product?.rows, (row, index) => (
                  <TableRow key={row?._id}>
                    <TableCell component="th" scope="row">
                      <div className="py-1">{`${
                        (page - 1) * size + index + 1
                      }`}</div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="py-1">
                        <img src={row?.logo?.url} className="h-10" />
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Link to={`/monitor/product/detail/${row?._id}`}>
                        <div className="py-1 text-base font-semibold hover:text-gray-500 hover:underline">{`${row?.name}`}</div>
                      </Link>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row?.product_group}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="py-1">{`${row?.full_title || '-'}`}</div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="flex flex-wrap gap-1">
                        <Link to={`/monitor/product/detail/${row?._id}`}>
                          <Button variant="outlined" color="info" size="small">
                            รายละเอียด
                          </Button>{' '}
                        </Link>
                        <Link to={`/monitor/product/module/${row?._id}`}>
                          <Button
                            variant="outlined"
                            color="secondary"
                            size="small"
                          >
                            โมดูล
                          </Button>{' '}
                        </Link>
                        <Link to={`/monitor/product/edit/${row?.id}`}>
                          <Button
                            variant="outlined"
                            color="warning"
                            size="small"
                          >
                            แก้ไข
                          </Button>
                        </Link>
                        <Button
                          variant="outlined"
                          color="error"
                          size="small"
                          onClick={() => {
                            handleDeleteProduct(row?._id);
                          }}
                        >
                          ลบ
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableHead>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  const renderAddButton = () => (
    <div className="flex flex-row justify-end mb-4">
      <Link to="/monitor/product/create">
        <Button variant="contained" size="small">
          เพิ่ม
        </Button>
      </Link>
    </div>
  );

  if (product.isLoading) {
    return <Loading />;
  }

  if (!product.isLoading && product.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-end">
          {/* <div>
            <BackButton />
          </div> */}
          <div>{renderAddButton()}</div>
        </div>
        {renderSearch()}
        {renderProduct()}
      </div>
    );
  }

  return <Error message={product?.message} />;
}

ProductManagement.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ProductManagement.defaultProps = {
  title: '',
  subtitle: '',
};
