import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import axios from 'axios';
import dayjs from 'dayjs';
import { PassportAuth } from '../../contexts/AuthContext';
import IARCLogo from '../../assets/img/iarc.png';
import { Clock } from '../Box';

export function HomeNavbar({ onMobileNavOpen }) {
  const history = useHistory();
  const { handleSignout } = useContext(PassportAuth);
  const handlePushToHome = () => {
    history.push('/');
  };
  const onSignOut = async () => {
    await handleSignout();
    history.push('/login');
  };

  return (
    <nav
      className="top-0 fixed z-10 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg shadow"
      style={{ background: '#212121' }}
    >
      <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
        <div className="w-full relative flex justify-between font-sans ">
          <div className="flex gap-4 items-center">
            <div>
              <img src={IARCLogo} className="h-10" />
            </div>
            <div
              onClick={() => {
                handlePushToHome();
              }}
              className="cursor-pointer text-white   leading-relaxed inline-block  whitespace-no-wrap font-semibold text-lg"
            >
              <div>Monitoring System</div>
              <div className="text-sm hidden md:block">
                ศูนย์วิศวกรรมระบบอัตโนมัติอัจฉริยะ | Intelligent Automation
                Engineering Center
              </div>
            </div>
          </div>
          <div className="hidden lg:flex text-white text-3xl self-center">
            <div className="text-base self-center">
              {dayjs().format('dd D MMM')}
            </div>
            <Clock />
          </div>
          <button
            className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-white block lg:hidden outline-none focus:outline-none"
            type="button"
            onClick={() => onMobileNavOpen()}
          >
            <i className="fas fa-bars"></i>
          </button>
        </div>
      </div>
    </nav>
  );
}
HomeNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func,
};
export default HomeNavbar;
