import {
  SYSTEM_ALL,
  SYSTEM_GET,
  SYSTEM_POST,
  SYSTEM_PUT,
  SYSTEM_DEL,
  SYSTEM_ERROR,
  SYSTEM_LOADING,
} from '../../actions/types';

const initialState = {
  system: null,
  isLoading: true,
  isCompleted: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SYSTEM_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case SYSTEM_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case SYSTEM_POST:
      return { isLoading: false, isCompleted: true };
    case SYSTEM_PUT:
      return { isLoading: false, isCompleted: true };
    case SYSTEM_DEL:
      return { isLoading: false, isCompleted: true };
    case SYSTEM_LOADING:
      return { isLoading: true, isCompleted: true };
    case SYSTEM_ERROR:
      return { isLoading: false, isCompleted: false };
    default:
      return state;
  }
}
