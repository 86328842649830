import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import {
  DropletProjectManagement,
  DropletManagement,
  ProjectManagement,
  SystemManagement,
  DropletDashboard,
  EditSystem,
  EditProject,
  DetailDroplet,
  DetailSystem,
  DetailProject,
  CreateSystem,
  CustomerManagement,
  ProductManagement,
  CreateProduct,
  EditProduct,
  DetailProduct,
  ProductModule,
  EditProductModule,
  EditCustomer,
  DetailCustomer,
  AdvisorManagement,
  EditAdvisor,
  DetailAdvisor,
  Dashboard,
  DetailSystemModule,
  EditSystemModule,
  EditNote,
  ProductFile,
  EditDroplet,
} from '../views/Monitor';
import { HomeNavbar } from '../components/Nevbars';
import { MainFooter } from '../components/Footers';
import { MainSidebar } from '../components/Sidebar';
import { NotFound } from '../components/Error';
// import accessRight from '../utils/functions/accessRight';
import * as actions from '../redux/actions';

export function Monitor() {
  const module = 'MONITOR';
  const prefix = '/monitor';
  const name = 'ศูนย์วิจัยระบบอัตโนมัติอัจฉริยะ';
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const me = useSelector((state) => state.me);

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const handleOnMobileNavOpen = () => {
    setMobileNavOpen(true);
  };
  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };

  return (
    <div className="min-h-screen">
      <MainSidebar
        onMobileClose={handleOnMobileNavClose}
        openMobile={isMobileNavOpen}
        me={me}
      />
      <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />

      <div className="relative lg:ml-64  min-h-screen  pt-20 px-8 ">
        <div className="py-4">
          <Switch>
            <Route exact path={`/`}>
              <Dashboard title="แดชบอร์ด" subtitle={name} />
            </Route>
            <Route exact path={`${prefix}/dashboard`}>
              <Dashboard title="แดชบอร์ด" subtitle={name} />
            </Route>
            <Route exact path={`${prefix}/droplet-dashboard`}>
              <DropletDashboard title="แดชบอร์ด" subtitle={name} />
            </Route>
            <Route exact path={`${prefix}/droplet_project`}>
              <DropletProjectManagement
                title="ดรอปเล็ตโปรเจค"
                subtitle={name}
              />
            </Route>
            <Route exact path={`${prefix}/droplet`}>
              <DropletManagement title="ดรอปเล็ต" subtitle={name} />
            </Route>
            <Route exact path={`${prefix}/droplet/detail/:id`}>
              <DetailDroplet title="ข้อมูลดรอปเล็ต" subtitle={name} />
            </Route>
            <Route exact path={`${prefix}/droplet/edit/:id`}>
              <EditDroplet title="แก้ไขดรอปเล็ต" subtitle={name} />
            </Route>
            <Route exact path={`${prefix}/project`}>
              <ProjectManagement title="โครงการ" subtitle={name} />
            </Route>
            <Route exact path={`${prefix}/project/edit/:id`}>
              <EditProject title="แก้ไขข้อมูลโครงการ" subtitle={name} />
            </Route>
            <Route exact path={`${prefix}/project/detail/:id`}>
              <DetailProject title="ข้อมูลโครงการ" subtitle={name} />
            </Route>
            <Route exact path={`${prefix}/system`}>
              <SystemManagement title="ระบบของศูนย์วิจัย" subtitle={name} />
            </Route>
            <Route exact path={`${prefix}/product/`}>
              <ProductManagement title="จัดการโปรดักส์" subtitle={name} />
            </Route>
            <Route exact path={`${prefix}/product/create`}>
              <CreateProduct title="สร้างโปรดักส์" subtitle={name} />
            </Route>
            <Route exact path={`${prefix}/product/edit/:id`}>
              <EditProduct title="สร้างโปรดักส์" subtitle={name} />
            </Route>
            <Route exact path={`${prefix}/product/detail/:id`}>
              <DetailProduct title="รายละเอียดโปรดักส์" subtitle={name} />
            </Route>
            <Route exact path={`${prefix}/product/module/:id`}>
              <ProductModule title="โมดูลของโปรดักส์" subtitle={name} />
            </Route>
            <Route exact path={`${prefix}/product/file/:id`}>
              <ProductFile title="เอกสารของโปรดักส์" subtitle={name} />
            </Route>
            <Route exact path={`${prefix}/product-module/edit/:id`}>
              <EditProductModule
                title="แก้ไขโมดูลของโปรดักส์"
                subtitle={name}
              />
            </Route>
            <Route exact path={`${prefix}/customer`}>
              <CustomerManagement title="จัดการลูกค้า" subtitle={name} />
            </Route>
            <Route exact path={`${prefix}/customer/edit/:id`}>
              <EditCustomer title="แก้ไขลูกค้า" subtitle={name} />
            </Route>{' '}
            <Route exact path={`${prefix}/customer/detail/:id`}>
              <DetailCustomer title="รายละเอียดลูกค้า" subtitle={name} />
            </Route>
            <Route exact path={`${prefix}/advisor`}>
              <AdvisorManagement title="จัดการที่ปรึกษา" subtitle={name} />
            </Route>
            <Route exact path={`${prefix}/advisor/edit/:id`}>
              <EditAdvisor title="แก้ไขที่ปรึกษา" subtitle={name} />
            </Route>{' '}
            <Route exact path={`${prefix}/advisor/detail/:id`}>
              <DetailAdvisor title="รายละเอียดที่ปรึกษา" subtitle={name} />
            </Route>
            <Route exact path={`${prefix}/note/edit/:id`}>
              <EditNote title="แก้ไขบันทึก" subtitle={name} />
            </Route>
            <Route exact path={`${prefix}/system/create`}>
              <CreateSystem title="สร้างระบบ" subtitle={name} />
            </Route>
            <Route exact path={`${prefix}/system/detail/:id`}>
              <DetailSystem title="ข้อมูลระบบของศูนย์วิจัย" subtitle={name} />
            </Route>
            <Route exact path={`${prefix}/system/edit/:id`}>
              <EditSystem title="แก้ไขข้อมูระบบ" subtitle={name} />
            </Route>
            <Route exact path={`${prefix}/system/module/detail/:id`}>
              <DetailSystemModule
                title="รายละเอียดโมดูลของระบบ"
                subtitle={name}
              />
            </Route>
            <Route exact path={`${prefix}/system/module/edit/:id`}>
              <EditSystemModule title="แก้ไขโมดูลของระบบ" subtitle={name} />
            </Route>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </div>
      </div>
      <div className="lg:ml-64">
        <MainFooter />
      </div>
    </div>
  );
}

export default Monitor;
