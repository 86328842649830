import {
  DROPLET_PROJECT_ALL,
  DROPLET_PROJECT_GET,
  DROPLET_PROJECT_POST,
  DROPLET_PROJECT_PUT,
  DROPLET_PROJECT_DEL,
  DROPLET_PROJECT_ERROR,
  DROPLET_PROJECT_LOADING,
} from '../../actions/types';

const initialState = {
  dropletProject: null,
  isLoading: true,
  isCompleted: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DROPLET_PROJECT_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case DROPLET_PROJECT_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case DROPLET_PROJECT_POST:
      return { isLoading: false, isCompleted: true };
    case DROPLET_PROJECT_PUT:
      return { isLoading: false, isCompleted: true };
    case DROPLET_PROJECT_DEL:
      return { isLoading: false, isCompleted: true };
    case DROPLET_PROJECT_LOADING:
      return { isLoading: true, isCompleted: true };
    case DROPLET_PROJECT_ERROR:
      return { isLoading: false, isCompleted: false };
    default:
      return state;
  }
}
