import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';
import { Button, Card } from '@mui/material';
import PropTypes from 'prop-types';
import _ from 'lodash';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/th';

import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { ViewTitle } from '../../components/ViewTitle';
import { ProductForm } from '../../components/Form';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ImageUpload } from '../../components/ImageUpload';
dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

export default function EditProduct({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const product = useSelector((state) => state.product);
  const [images, setImages] = useState([]);

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {},
  });

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  useEffect(() => {
    dispatch(actions.productGet(params.id));

    return () => {};
  }, [params]);

  const onSubmit = async (data) => {
    try {
      const payload = {
        ...data,
        logo: _.isEmpty(images)
          ? data.image
          : { image: images?.[0]?.data_url, alt: '' },
      };
      await dispatch(actions.productPut(params.id, payload));
      alert('สำเร็จ');

      history.goBack();
      dispatch(actions.productAll({ name: '', page: 1, size: 10 }));
    } catch (error) {
      alert(error.message);
      console.error(error);
    }
  };

  const renderForm = () => (
    <div>
      <Card className="p-4">
        <form onSubmit={handleSubmit(onSubmit)}>
          <ProductForm control={control} errors={errors} product={product} />
          <ImageUpload images={images} maxNumber={1} setImages={setImages} />
          <div className="flex flex-row justify-end gap-1 mt-2">
            <Button variant="contained" type="submit">
              บันทึก
            </Button>
          </div>
        </form>
      </Card>
    </div>
  );

  if (product?.isLoading || product?.rows) {
    return <Loading />;
  }

  if (product?.isCompleted && !product?.isLoading && !product?.rows) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        {renderForm()}
      </div>
    );
  }

  return <Error />;
}

EditProduct.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditProduct.defaultProps = {
  title: '',
  subtitle: '',
};
