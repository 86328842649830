import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Button,
  Card,
  TextField,
  InputAdornment,
  Modal,
  Fade,
  Backdrop,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as actions from '../../redux/actions';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { Link } from 'react-router-dom';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  height: '70vh',
  boxShadow: 24,
  p: 3,
};

export default function DropletManagement({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const droplets = useSelector((state) => state.droplet);
  const dropletProject = useSelector((state) => state.dropletProject);
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [digitalOceanData, setDigitalOceanData] = useState(null);
  const [selected, setSelected] = useState(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    dispatch(actions.dropletAll({ size: '', page: 1 }));
    setOpen(true);
  };
  const handleClose = () => {
    dispatch(actions.dropletAll({ size, page }));
    setOpen(false);
  };

  useEffect(() => {
    const fetchDroplet = async () => {
      try {
        const { data } = await axios.get(
          `https://api.digitalocean.com/v2/droplets?page=${1}&per_page=${200}`,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_DIGITALOCEAN_TOKEN}`,
            },
          },
        );
        setDigitalOceanData(data);
        dispatch(actions.dropletAll({ size, page }));
        dispatch(actions.dropletProjectAll({ size: null, page: 1 }));
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchDroplet();

    return () => {};
  }, [page, size]);

  useEffect(() => {
    setTotal(droplets?.total);
    return () => {};
  }, [droplets]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      // setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>{' '}
        <div className="w-full md:w-1/2 flex justify-end">
          <div>
            <div className="text-right">ราคารวม</div>
            <div className="text-lg">
              <span className="font-semibold ">
                {droplets?.analyze?.price}$
              </span>{' '}
              หรือ{' '}
              <span className="font-semibold ">
                {droplets?.analyze?.price * 38} บาท
              </span>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const onSubmit = async (data) => {
    try {
      if (selected) {
        const payload = selected;
        payload.type = data.type;
        payload.dropletID = payload.id;
        setSelected(null);
        handleClose();
        await dispatch(actions.dropletCreate(payload));
        await dispatch(actions.dropletAll({ size, page }));
      } else {
        alert('กรุณาเลือกข้อมูล Project ที่ต้องการสร้าง');
      }
    } catch (error) {
      alert(error.message);
      console.error(error);
    }
  };

  const handleDeleteDroplet = async (id) => {
    const confirm = window.confirm('ยืนยันการลบ');
    if (confirm) {
      await dispatch(actions.dropletDelete(id));
      await dispatch(actions.dropletAll({ size, page }));
    }
  };

  const renderDroplet = () => (
    <div className="mt-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size={'small'}>
            <TableHead>
              <TableRow className="bg-gray-200">
                <TableCell>
                  <div className="font-bold py-1">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ชื่อ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">IP Address</div>
                </TableCell>

                <TableCell>
                  <div className="font-bold py-1">สเปค</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ราคา</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(droplets?.rows) ? (
                _.map(droplets?.rows, (row, index) => (
                  <TableRow key={row?.id}>
                    <TableCell component="th" scope="row">
                      <div className="py-1">{`${
                        (page - 1) * size + index + 1
                      }`}</div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="py-1">{`${row?.name}`}</div>
                      <div className="flex gap-2">
                        {row?.portainer_url && (
                          <a
                            href={row?.portainer_url}
                            className="bg-blue-500 text-white px-2 py-1 rounded-md"
                          >
                            <i className="fab fa-docker"></i> Portainer{' '}
                          </a>
                        )}
                        {row?.portainer_url && (
                          <a
                            href={row?.nginx_url}
                            className="bg-green-500 text-white px-2 py-1 rounded-md"
                          >
                            <i className="fas fa-server"></i> Nginx{' '}
                          </a>
                        )}
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {_.map(row.networks.v4, (address, index) => (
                        <div
                          key={index}
                          className="py-1"
                        >{`${address.ip_address} ( ${address.type} )`}</div>
                      ))}
                    </TableCell>

                    <TableCell component="th" scope="row">
                      <div className="text-xs">
                        <p>{`Region : ${row?.region?.slug || '-'}`}</p>
                        <p>{`CPU : ${row?.size?.vcpus}`}</p>
                        <p>{`RAM : ${row?.size?.memory} MB`}</p>
                        <p>{`Disk : ${row?.size?.disk} GB`}</p>
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="py-1">
                        {`$${row?.size?.price_monthly}/mo`}
                        <br />
                        {`${row?.size?.price_monthly * 38} THB/mo`}
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="flex flex-wrap gap-1">
                        <Button
                          variant="outlined"
                          color="info"
                          size="small"
                          onClick={() => {
                            history.push('/monitor/droplet/detail/' + row._id);
                          }}
                        >
                          รายละเอียด
                        </Button>

                        <Button
                          variant="outlined"
                          color="warning"
                          size="small"
                          onClick={() => {
                            history.push('/monitor/droplet/edit/' + row._id);
                          }}
                        >
                          แก้ไข
                        </Button>
                        <Button
                          variant="outlined"
                          color="error"
                          size="small"
                          onClick={() => {
                            handleDeleteDroplet(row?._id);
                          }}
                        >
                          ลบ
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  const renderDigitalOceanDropletList = () => (
    <div className="p-2">
      <TableContainer className="shadow-md rounded-md ">
        <Table sx={{ minWidth: 650 }} size={'small'}>
          <TableHead>
            <TableRow className="bg-gray-200">
              <TableCell>
                <div className="font-bold py-1">ลำดับที่</div>
              </TableCell>
              <TableCell>
                <div className="font-bold py-1">โปรเจค</div>
              </TableCell>
              <TableCell>
                <div className="font-bold py-1">ดำเนินการ</div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {digitalOceanData?.droplets.map((dropletDO, index) => (
              <TableRow key={dropletDO.id}>
                <TableCell component="th" scope="row">
                  <div className="py-1">{`${index + 1}`}</div>
                </TableCell>
                <TableCell component="th" scope="row">
                  <div className="py-1">{`${dropletDO?.name}`}</div>
                </TableCell>
                <TableCell component="th" scope="row">
                  {_.find(droplets.rows, (row) => {
                    return row?.dropletID == dropletDO.id;
                  }) ? (
                    <div>
                      <Button
                        variant="contained"
                        color="info"
                        size="small"
                        disabled
                      >
                        มีอยู่แล้ว
                      </Button>
                    </div>
                  ) : dropletDO.id === selected?.id ? (
                    <Button
                      variant="contained"
                      color="success"
                      size="small"
                      onClick={() => {
                        setSelected(null);
                      }}
                    >
                      เลือก
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      color="success"
                      size="small"
                      onClick={() => {
                        setSelected(dropletDO);
                      }}
                    >
                      เลือก
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );

  const renderModal = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Card sx={style} className="max-w-4xl">
          <div className="text-lg font-semibold">เพิ่มข้อมูล</div>
          <div className="py-2">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="px-2">
                <Controller
                  name={'type'}
                  control={control}
                  defaultValue={''}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                      <InputLabel id="type" size={'small'}>
                        โปรเจค
                      </InputLabel>
                      <Select
                        {...field}
                        label="แผนก"
                        required
                        size={'small'}
                        fullWidth
                      >
                        {_.size(dropletProject?.rows) ? (
                          _.map(dropletProject.rows, (row) => (
                            <MenuItem key={row._id} value={row._id}>
                              {row.name}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem>
                            <MenuItem disabled value="">
                              <em>ไม่มีข้อมูล</em>
                            </MenuItem>
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  )}
                />
              </div>
              <div className="overflow-y-scroll" style={{ maxHeight: '50vh' }}>
                {renderDigitalOceanDropletList()}
              </div>
              <div className="flex flex-row justify-end gap-1">
                <Button variant="contained" type="submit">
                  บันทึก
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  ยกเลิก
                </Button>
              </div>
            </form>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderAddButton = () => (
    <div className="flex flex-row justify-end pb-4">
      <Button variant="contained" size="small" onClick={handleOpen}>
        เพิ่ม
      </Button>
    </div>
  );

  if (droplets.isLoading && isLoading) {
    return <Loading />;
  }

  if (!droplets.isLoading && droplets.isCompleted) {
    return (
      <div>
        {renderTitle()}
        {renderModal()}
        <div className="flex flex-row justify-end pb-4">
          <div>{renderAddButton()}</div>
        </div>
        {renderSearch()}
        {renderDroplet()}
      </div>
    );
  }

  return <Error message={droplets?.message} />;
}

DropletManagement.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DropletManagement.defaultProps = {
  title: '',
  subtitle: '',
};
