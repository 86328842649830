import React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

function BarChart({
  series,
  labels,
  anathorChartOptions = {},
  width = 400,
  colors,
  horizontal = true,
}) {
  /**
   * @type {ApexOptions}
   */
  const options = {
    xaxis: {
      categories: labels || [],
    },
    plotOptions: {
      bar: {
        horizontal,
      },
    },
    chart: {
      ...anathorChartOptions,
    },
    colors: colors,
    dataLabels: {
      ...(anathorChartOptions?.dataLabels || {}),
    },
  };

  return (
    <div>
      <Chart options={options} series={series} type="bar" width={width} />
    </div>
  );
}

export default BarChart;
