import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

export function DropletForm({ errors, droplet, control }) {
  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-2">
        <Controller
          name={'management_type'}
          control={control}
          defaultValue={droplet ? droplet.management_type : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl fullWidth size="small">
              <InputLabel>วิธีการเข้าไปจัดการ</InputLabel>
              <Select label="วิธีการเข้าไปจัดการ" {...field}>
                <MenuItem value="CLI">CLI</MenuItem>
                <MenuItem value="PORTAINER">Portainer</MenuItem>
              </Select>
            </FormControl>
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'portainer_url'}
          control={control}
          defaultValue={droplet ? droplet.portainer_url : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="หน้าที่เข้าไปจัดการ Portainer"
              fullWidth
              size={'small'}
            />
          )}
        />
      </div>{' '}
      <div className="w-full px-1 py-2">
        <Controller
          name={'nginx_url'}
          control={control}
          defaultValue={droplet ? droplet.nginx_url : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="หน้าที่เข้าไปจัดการ Nginx"
              fullWidth
              size={'small'}
            />
          )}
        />
      </div>{' '}
      <div className="w-full px-1 py-2">
        <Controller
          name={'is_service_droplet'}
          control={control}
          defaultValue={droplet ? droplet.is_service_droplet : ''}
          render={({ field }) => (
            <FormControlLabel
              label="เป็น Droplet ให้บริการ Service"
              control={<Checkbox {...field} defaultChecked={field.value} />}
            />
          )}
        />
      </div>
    </div>
  );
}

DropletForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.object,
    description: PropTypes.object,
    start_date: PropTypes.object,
    end_date: PropTypes.object,
  }),

  control: PropTypes.object,
};

export default DropletForm;
