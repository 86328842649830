import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  Card,
  CardContent,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/th';
import { Controller, useForm } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';

import * as actions from '../../redux/actions';

import { Loading } from '../Loading';
import { FileTable } from '../Table';
import { Error } from '../Error';
import { FILE_TYPE } from '../../utils/constants';
dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

export default function FileInProduct() {
  const dispatch = useDispatch();

  const params = useParams();
  const file = useSelector((state) => state.file);

  const [fileSize, setFileSize] = useState(10);
  const [filePage, setFilePage] = useState(1);
  const [total, setTotal] = useState(1);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFileType, setSelectedFileType] = useState();
  const { control, handleSubmit, setValue } = useForm();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
  });

  useEffect(() => {
    setSelectedFiles(acceptedFiles);
    setValue('file_name', acceptedFiles?.[0]?.path);
    return () => {};
  }, [_.size(acceptedFiles)]);

  useEffect(() => {
    dispatch(
      actions.fileAll({
        product: params.id,
        page: 1,
        size: fileSize,
        fileType: selectedFileType,
      }),
    );

    return () => {};
  }, [fileSize, selectedFileType]);

  useEffect(() => {
    if (file?.rows) {
      setTotal(file.total);
    }

    return () => {};
  }, [file]);

  const handleDeleteFile = async (fileId) => {
    try {
      const confirm = window.confirm('ยืนยันการลบ');
      if (confirm) {
        await dispatch(actions.fileDelete(fileId));
        dispatch(
          actions.fileAll({
            product: params.id,
            page: filePage,
            size: fileSize,
            fileType: selectedFileType,
          }),
        );
      }
    } catch (error) {
      alert(`ไม่สามารถลบได้ ${error?.message}`);
    }
  };

  const handleCreateFile = async (data) => {
    try {
      const payload = {
        ...data,
        product: params.id,
        file: _.isEmpty(selectedFiles) ? undefined : selectedFiles[0],
      };

      await dispatch(actions.fileCreate(payload));
      setValue('file_name', '');
      setSelectedFiles([]);
      dispatch(
        actions.fileAll({
          product: params.id,
          page: 1,
          size: fileSize,
          fileType: selectedFileType,
        }),
      );
    } catch (error) {
      alert(`สร้างบันทึกไม่สำเร็จ ${error?.message}`);
    }
  };
  if (file?.isLoading) {
    return <Loading />;
  }

  if (file?.isCompleted && !file?.isLoading) {
    return (
      <div>
        <Card>
          <div className="flex justify-end flex-wrap p-2">
            <div className="md:w-1/4">
              <FormControl fullWidth size="small">
                <InputLabel>Type ของเอกสาร</InputLabel>
                <Select
                  fullWidth
                  size="small"
                  label="Type ของเอกสาร"
                  value={selectedFileType}
                  onChange={(e) => {
                    setSelectedFileType(e.target.value);
                  }}
                >
                  <MenuItem value={''}>ไม่เลือก</MenuItem>
                  {_.map(FILE_TYPE, (eachNoteType, index) => (
                    <MenuItem key={index} value={eachNoteType?.status_code}>
                      {eachNoteType?.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </Card>
        <FileTable
          file={file}
          handleDeleteFile={handleDeleteFile}
          page={filePage}
          setPage={setFilePage}
          setSize={setFileSize}
          size={fileSize}
          total={total}
        />

        <Card className="my-2">
          <CardContent>
            <div>อัพโหลดไฟล์</div>
            <form onSubmit={handleSubmit(handleCreateFile)}>
              <div className="w-full p-2">
                <Controller
                  control={control}
                  name="file_name"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="ชื่อไฟล์"
                      required
                      size="small"
                      fullWidth
                    />
                  )}
                />
              </div>
              <div className="w-full p-2">
                <Controller
                  control={control}
                  name="file_type"
                  render={({ field }) => (
                    <FormControl fullWidth size="small">
                      <InputLabel>Type ของเอกสาร</InputLabel>
                      <Select
                        fullWidth
                        size="small"
                        required
                        label="Type ของเอกสาร"
                        {...field}
                      >
                        <MenuItem value={''}>ไม่เลือก</MenuItem>
                        {_.map(FILE_TYPE, (eachNoteType, index) => (
                          <MenuItem
                            key={index}
                            value={eachNoteType?.status_code}
                          >
                            {eachNoteType?.description}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </div>
              <section className="container">
                <div {...getRootProps({ className: 'dropzone' })}>
                  <input {...getInputProps()} />
                  <p className="font-system w-72 m-1 p-4 bg-gray-50 border ">
                    ลากไฟล์มาที่นี้ หรือ กดที่นี่เพื่อเลือกไฟล์
                  </p>
                </div>
                <aside>
                  {_.size(selectedFiles) > 0 && (
                    <ul className="list-disc list-inside mx-2 my-3">
                      {_.map(acceptedFiles, (_file, index) => (
                        <li className="font-system " key={index}>
                          {_file?.path}
                        </li>
                      ))}
                    </ul>
                  )}
                </aside>
              </section>
              {!_.isEmpty(selectedFiles) && (
                <div className="flex justify-center my-2">
                  <Button color="success" variant="contained" type="submit">
                    ยืนยัน
                  </Button>
                </div>
              )}
            </form>
          </CardContent>
        </Card>
      </div>
    );
  }

  return <Error />;
}
