import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import _ from 'lodash';
import {
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from '@mui/material';
import { NOTE_TYPE } from '../../utils/constants';

function NoteForm({ control, note }) {
  return (
    <div className="flex flex-wrap my-2">
      <div className="w-full p-2 lg:w-1/2">
        <Controller
          control={control}
          name="message_type"
          defaultValue={note?.message_type}
          render={({ field }) => (
            <FormControl fullWidth>
              <InputLabel>Tag</InputLabel>
              <Select label="Tag" {...field} size="small" fullWidth>
                <MenuItem value={''}></MenuItem>
                {_.map(NOTE_TYPE, (eachType, index) => (
                  <MenuItem value={eachType?.status_code} key={index}>
                    {eachType?.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </div>
      <div className="p-2 w-full">
        <Controller
          control={control}
          name="message"
          defaultValue={note?.message}
          render={({ field }) => (
            <TextField
              size="small"
              fullWidth
              {...field}
              required
              multiline
              rows={6}
              placeholder="ใส่บันทึกของคุณ"
              helperText={
                <span>
                  <i class="fab fa-markdown mr-2"></i>Markdown Support
                </span>
              }
            />
          )}
        />
      </div>
    </div>
  );
}

NoteForm.propTypes = { control: PropTypes.object, note: PropTypes.object };

export default NoteForm;
