import { TextField } from '@mui/material';

import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import _ from 'lodash';

export function AdvisorForm({ errors, advisor, control }) {
  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-2">
        <Controller
          name={'name'}
          control={control}
          defaultValue={advisor ? advisor.name : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อ"
              required
              fullWidth
              size={'small'}
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'department'}
          control={control}
          defaultValue={advisor ? advisor.department : 'วิศวกรรมอุตสาหการ'}
          render={({ field }) => (
            <TextField
              {...field}
              label="ภาควิชา/สาขาวิชา"
              fullWidth
              size={'small'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'faculty'}
          control={control}
          defaultValue={advisor ? advisor.faculty : 'วิศวกรรมศาสตร์'}
          render={({ field }) => (
            <TextField {...field} label="คณะ" fullWidth size={'small'} />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'university'}
          control={control}
          defaultValue={
            advisor ? advisor.university : 'มหาวิทยาลัยสงขลานครินทร์'
          }
          render={({ field }) => (
            <TextField {...field} label="สถาบัน" fullWidth size={'small'} />
          )}
        />
      </div>
    </div>
  );
}

AdvisorForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.object,
    description: PropTypes.object,
    start_date: PropTypes.object,
    end_date: PropTypes.object,
  }),
  advisor: PropTypes.object,
  control: PropTypes.object,
};

export default AdvisorForm;
