import {
  DROPLET_ALL,
  DROPLET_GET,
  DROPLET_POST,
  DROPLET_PUT,
  DROPLET_DEL,
  DROPLET_ERROR,
  DROPLET_LOADING,
} from '../types';

import api from '../../../utils/functions/api';

export const dropletCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: DROPLET_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/droplet`,
      payload,
    );
    dispatch({ type: DROPLET_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: DROPLET_ERROR });
    throw new Error(error);
  }
};

export const dropletAll = (params) => async (dispatch) => {
  try {
    const { name = '', size = '', page = 1 } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/droplet?name=${name}&size=${size}&page=${page}`,
    );
    if (status === 200) {
      dispatch({ type: DROPLET_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: DROPLET_ERROR });
    throw new Error(error);
  }
};

export const dropletGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/droplet/${id}`,
    );
    if (status === 200) {
      dispatch({ type: DROPLET_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: DROPLET_ERROR });
    throw new Error(error);
  }
};

export const dropletPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: DROPLET_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/droplet/${id}`,
      payload,
    );
    dispatch({ type: DROPLET_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: DROPLET_ERROR });
    throw new Error(error);
  }
};

export const dropletDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: DROPLET_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/droplet/${id}`,
    );
    dispatch({ type: DROPLET_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: DROPLET_ERROR });
    throw new Error(error);
  }
};
