import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';
import { Button, Card } from '@mui/material';
import PropTypes from 'prop-types';
import _ from 'lodash';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/th';
import axios from 'axios';

import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { ViewTitle } from '../../components/ViewTitle';
import { DropletForm } from '../../components/Form';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

export default function EditDroplet({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const droplet = useSelector((state) => state.droplet);
  const [dropletData, setDropletData] = useState({});
  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {},
  });

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  useEffect(() => {
    dispatch(actions.dropletGet(params.id));

    return () => {};
  }, [params]);

  const getDropletDigitalOcean = async () => {
    try {
      const { data } = await axios.get(
        `https://api.digitalocean.com/v2/droplets/${droplet?.dropletID}`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_DIGITALOCEAN_TOKEN}`,
          },
        },
      );
      setDropletData(data);
      console.log('Data', data);
    } catch (error) {
      alert(
        `ไม่พบข้อมูลดรอปเล็ตใน DigitalOcean ${error?.message} ลองเปิดดู หรืออาจจะลบ Droplet นี้ไป`,
      );
      console.error(error);
    }
  };

  useEffect(() => {
    if (droplet?.dropletID) {
      getDropletDigitalOcean();
    }

    return () => {};
  }, [droplet]);

  const onSubmit = async (data) => {
    try {
      const payload = {
        ...dropletData?.droplet,
        ...data,
      };
      await dispatch(actions.dropletPut(params.id, payload));
      history.goBack();
    } catch (error) {
      alert(error.message);
      console.error(error);
    }
  };

  const renderForm = () => (
    <div>
      <Card className="p-4">
        <form onSubmit={handleSubmit(onSubmit)}>
          <DropletForm control={control} errors={errors} droplet={droplet} />
          <div className="flex flex-row justify-end gap-1 mt-2">
            <Button variant="contained" type="submit">
              บันทึก
            </Button>
          </div>
        </form>
      </Card>
    </div>
  );

  if (droplet?.isLoading || droplet?.rows) {
    return <Loading />;
  }

  if (droplet?.isCompleted && !droplet?.isLoading && !droplet?.rows) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        {renderForm()}
      </div>
    );
  }

  return <Error />;
}

EditDroplet.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditDroplet.defaultProps = {
  title: '',
  subtitle: '',
};
