import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { Button, Box, Tab, Tabs } from '@mui/material';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import copy from 'copy-to-clipboard';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/th';

import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { SystemTable } from '../../components/Table';
import { NoteInProject, SystemInProject } from '../../components/SubPage';
dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default function DetailProject({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const project = useSelector((state) => state.project);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [selectedTab, setSelectedTab] = useState('table');

  useEffect(() => {
    dispatch(actions.projectGet(id));
    return () => {};
  }, [id]);

  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleDataCopyToClipboard = (data) => {
    const system = _.map(data?.systems, (_system, index) => {
      const userList = _.reduce(
        _system.user,
        function (result, eachUser) {
          if (!eachUser.superuser) {
            return (
              result +
              `username : ${eachUser.user}\npassword : ${eachUser.password}`
            );
          }
          return result;
        },
        '',
      );
      return `${index + 1}. ${_system?.name}\n${_system?.url}\n${userList}`;
    });
    const text = `${data?.name}\n${system.join('')}`;
    copy(text);
    handleOpenSnackbar();
  };

  const renderSnackbar = () => {
    return (
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <Alert
          onClose={handleCloseSnackBar}
          severity="info"
          sx={{ width: '100%' }}
        >
          คัดลอกข้อมูลระบบ
        </Alert>
      </Snackbar>
    );
  };

  const renderDetail = () => (
    <div>
      <div className="w-full p-4 bg-white ">
        <div className="flex justify-between">
          <div>
            <b>โครงการ{`${project?.name}`}</b>
            <p>โครงการ{`${project?.description}`}</p>
            <p className="mb-4">
              {dayjs(project?.start_date).locale('th').format('DD MMM BBBB')} -{' '}
              {dayjs(project?.end_date).locale('th').format('DD MMM BBBB')}
            </p>
          </div>
          <div>
            <Button
              onClick={() => {
                handleDataCopyToClipboard(project);
              }}
            >
              <i class="fas fa-copy" aria-hidden="true"></i>
            </Button>
          </div>
        </div>
      </div>{' '}
      <Box sx={{ width: '100%' }} className="my-2">
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            bgcolor: 'background.paper',
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={(event, newValue) => {
              setSelectedTab(newValue);
            }}
            aria-label="basic tabs example"
          >
            <Tab label="รายการระบบ" value="table" />
            <Tab label="บันทึก" value="note" />
          </Tabs>
        </Box>
      </Box>
      {selectedTab === 'table' && <SystemInProject />}
      {selectedTab === 'note' && <NoteInProject />}
    </div>
  );

  if (project.isLoading || project.rows) {
    return <Loading />;
  }

  if (!project.isLoading && project.isCompleted) {
    return (
      <div>
        {renderTitle()}
        {renderSnackbar()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        {renderDetail()}
      </div>
    );
  }

  return <Error message={project?.message} />;
}

DetailProject.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailProject.defaultProps = {
  title: '',
  subtitle: '',
};
