import React from 'react';
import PropTypes from 'prop-types';

export default function ViewTitle({ title, subtitle }) {
  return (
    <div
      className="py-4 mb-4 px-2 cursor-pointer border-l-4 border-gray-200"
      style={{ borderColor: '#212121' }}
    >
      <div className="text-xl font-medium  text-gray-600 hover:text-black  px-2 ">
        {title}
      </div>
      <div className="text-xs font-medium  text-gray-600 hover:text-black  px-2 ">
        {subtitle}
      </div>
    </div>
  );
}

ViewTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ViewTitle.defaultProps = {
  title: '',
  subtitle: '',
};
