export const FILE_TYPE = {
  MANUAL: {
    status_code: 'MANUAL',
    description: 'คู่มือ',
  },
  SPEC: {
    status_code: 'SPEC',
    description: 'สเปคระบบ',
  },
  INFORMATION: {
    status_code: 'INFORMATION',
    description: 'แผ่นข้อมูล/ประชาสัมพันธ์',
  },
  REPORT: {
    status_code: 'REPORT',
    description: 'รายงาน',
  },
  OTHERS: {
    status_code: 'OTHERS',
    description: 'อื่นๆ',
  },
};

export default FILE_TYPE;
