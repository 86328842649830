import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { Button, Card } from '@mui/material';
import PropTypes from 'prop-types';
import _ from 'lodash';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/th';

import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { ViewTitle } from '../../components/ViewTitle';
import { ProductForm } from '../../components/Form';
import { ImageUpload } from '../../components/ImageUpload';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

export default function CreateProduct({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [images, setImages] = useState([]);

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {},
  });

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data) => {
    try {
      const payload = {
        ...data,
        logo: _.isEmpty(images)
          ? null
          : { image: images?.[0]?.data_url, alt: '' },
      };

      console.log('Payload', payload);
      await dispatch(actions.productCreate(payload));
      alert('สำเร็จ');

      history.goBack();
    } catch (error) {
      alert(error.message);
      console.error(error);
    }
  };

  const renderForm = () => (
    <div>
      <Card className="p-4">
        <form onSubmit={handleSubmit(onSubmit)}>
          <ProductForm control={control} errors={errors} />
          <ImageUpload images={images} maxNumber={1} setImages={setImages} />
          <div className="flex flex-row justify-end gap-1 mt-2">
            <Button variant="contained" type="submit">
              บันทึก
            </Button>
          </div>
        </form>
      </Card>
    </div>
  );

  return (
    <div>
      {renderTitle()}
      <div className="flex flex-row justify-start pb-4">
        <div>
          <BackButton />
        </div>
      </div>
      {renderForm()}
    </div>
  );
}

CreateProduct.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreateProduct.defaultProps = {
  title: '',
  subtitle: '',
};
