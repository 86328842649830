import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import {
  Accordion,
  AccordionSummary,
  Checkbox,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  AccordionDetails,
  Button,
  Card,
  CardContent,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from '@mui/material';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/th';
import { useForm } from 'react-hook-form';

import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { NoteForm } from '../../components/Form';
import { CommentBox } from '../../components/Box';
import { ImageUpload } from '../../components/ImageUpload';
import { NOTE_TYPE } from '../../utils/constants';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

export default function DetailSystem({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const system = useSelector((state) => state.system);
  const note = useSelector((state) => state.note);
  const [size, setSize] = useState(5);
  const [images, setImages] = useState([]);
  const [selectedNotetype, setSelectedNoteType] = useState();
  const { control, handleSubmit, setValue } = useForm();

  useEffect(() => {
    dispatch(actions.systemGet(id));
    return () => {};
  }, [id]);

  useEffect(() => {
    dispatch(
      actions.noteAll({
        system: id,
        page: 1,
        size,
        messageType: selectedNotetype,
      }),
    );

    return () => {};
  }, [size, selectedNotetype]);

  const handleCreateNote = async (data) => {
    try {
      const payload = {
        ...data,
        customer: system?.customer?._id || null,
        system: id,
        product: system?.product?._id || null,
        project: system?.project?._id || null,
        images: _.isEmpty(images)
          ? []
          : _.map(images, (eachImage) => ({
              image: eachImage?.data_url,
              alt: '',
            })),
      };

      await dispatch(actions.noteCreate(payload));
      setValue('message', '');
      dispatch(actions.noteAll({ system: id, page: 1, size }));
    } catch (error) {
      alert(`สร้างบันทึกไม่สำเร็จ ${error?.message}`);
    }
  };

  const handleDeleteNote = async (noteId) => {
    try {
      const confirm = window.confirm('ยืนยันการลบ');
      if (confirm) {
        await dispatch(actions.noteDelete(noteId));
        dispatch(actions.noteAll({ system: id, page: 1, size }));
      }
    } catch (error) {
      alert(`ไม่สามารถลบได้ ${error?.message}`);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderDetail = () => (
    <div>
      <div className="w-full bg-white p-4 h-auto tracking-wide mb-4 border border-black-800  rounded-lg relative">
        <div className="small-banner w-1 h-24 bg-blue-600 absolute rounded-tl-md"></div>
        <div className="flex justify-between">
          <div>
            <h5 className="text-2xl font-semibold p-6">
              ระบบ {`${system?.name}`}
              <p className="text-sm font-normal text-gray-500">
                {`${system?.description || ''}`}
              </p>
            </h5>
          </div>

          <div className="flex gap-2">
            <div className="px-2">
              <b>แท็ก</b>
              <p className="text-sm bg-gray-200 px-3 py-1 rounded-full font-medium text-gray-800 hidden md:block">
                {`${system?.tag || '-'}`}
              </p>
            </div>
          </div>
        </div>
        <hr className="my-4 mx-6" />
        <div className="flex flex-wrap px-6">
          <div className="w-full md:w-1/2">
            <div className="p-2">
              <b>โครงการ</b>
              <Link to={`/monitor/project/detail/${system?.project?._id}`}>
                <p className="text-base font-regular hover:text-gray-500 hover:underline">
                  {`${system?.project?.name || ''}`} ({' '}
                  {dayjs(system?.project?.start_date)
                    .locale('th')
                    .format('DD MMM BBBB')}{' '}
                  -{' '}
                  {dayjs(system?.project?.end_date)
                    .locale('th')
                    .format('DD MMM BBBB')}
                  )
                </p>
              </Link>
              <p className="text-sm font-regular text-gray-500">
                {`${system?.project?.description || ''}`}
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/4">
            <b>โปรดักส์</b>
            <Link to={`/monitor/product/detail/${system?.product?._id}`}>
              <img src={system?.product?.logo?.url} className="h-16" />
            </Link>
          </div>
          <div className="w-full md:w-1/4">
            <b>ที่ปรึกษา</b>
            <Link to={`/monitor/advisor/detail/${system?.advisor?._id}`}>
              <div className="flex flex-wrap">
                <img
                  src={system?.advisor?.image?.url}
                  className="rounded-full object-cover h-16 w-16 object-center "
                />
                <div className="px-2 self-center text-sm">
                  {system?.advisor?.name}
                </div>
              </div>
            </Link>
          </div>
        </div>
        <hr className="my-4 mx-6" />
        <div className="flex flex-wrap px-6">
          <div className="w-full md:w-1/3 px-1">
            <b>สถานะระบบ</b>
            <p className="text-sm  text-gray-500">
              วันที่เริ่ม :{' '}
              {dayjs(system?.start_date).locale('th').format('DD MMM BB')}{' '}
            </p>
            <p className="text-sm  text-gray-500">
              วันที่สิ้นสุด :{' '}
              {dayjs(system?.end_date).locale('th').format('DD MMM BB')}{' '}
            </p>{' '}
            <p className="text-sm  text-gray-500">
              Version : {`${system?.version || '-'}`}
            </p>
          </div>
          <div className="w-full md:w-1/3 px-1">
            <b>ลูกค้า</b>
            <Link to={`/monitor/customer/detail/${system?.customer?._id}`}>
              <div className="flex flex-wrap my-1">
                {system?.customer?.logo?.url && (
                  <img
                    src={system?.customer?.logo?.url}
                    className="rounded-full object-cover h-16 w-1/4 object-center bg-gray-200"
                  />
                )}
                <div className="px-2 self-center text-sm w-3/4">
                  {system?.customer?.name || '-'}
                </div>
              </div>
            </Link>
          </div>{' '}
          <div className="w-full md:w-1/3 px-1">
            <p className="font-semibold px-2">หมายเหตุ</p>
            <p className="text-sm px-2">{`${system?.remark || '-'}`}</p>
          </div>
        </div>{' '}
        <hr className="my-4 mx-6" />
        <div className="px-6">
          <p className="font-semibold px-2">สถานะการใช้งาน</p>
          <div className="flex">
            <div className="text-sm flex gap-2 self-center p-2 rounded-lg">
              <div className="gap-2 flex">
                <div>
                  <div
                    className={`text-sm  ${
                      system?.status ? ' bg-green-500' : ' bg-red-500'
                    } px-3 py-1 rounded-full font-medium text-gray-800`}
                  >
                    {system?.status ? (
                      <i className="fas fa-check text-white text-sm" />
                    ) : (
                      <i className="fas fa-times text-white text-sm" />
                    )}
                  </div>
                </div>
                <div className="self-center">เปิดใช้งาน</div>
              </div>
              <div className="gap-2 flex">
                <div>
                  <div
                    className={`text-sm  ${
                      system?.success ? ' bg-green-500' : ' bg-red-500'
                    } px-3 py-1 rounded-full font-medium text-gray-800 `}
                  >
                    {system?.success ? (
                      <i className="fas fa-check text-white text-sm" />
                    ) : (
                      <i className="fas fa-times text-white text-sm" />
                    )}
                  </div>
                </div>
                <div className="self-center">สำเร็จ</div>
              </div>
              <div className="gap-2 flex">
                <div>
                  <div
                    className={`text-sm  ${
                      system?.on_paid ? ' bg-green-500' : ' bg-red-500'
                    } px-3 py-1 rounded-full font-medium text-gray-800 `}
                  >
                    {system?.on_paid ? (
                      <i className="fas fa-check text-white text-sm" />
                    ) : (
                      <i className="fas fa-times text-white text-sm" />
                    )}
                  </div>
                </div>
                <div className="self-center">ชำระเงิน</div>
              </div>
            </div>
          </div>
        </div>
        <hr className="my-4 mx-6" />
        <div className="p-2 flex flex-wrap w-full">
          <div className="w-full lg:w-1/3 bg-gray-100 p-6  rounded-xl border shadow-md border-gray-50">
            <p className="text-gray-500 text-sm">URL</p>
            <p
              className="text-center text-xl font-semibold text-blue-600 hover:text-blue-400 hover:underline cursor-pointer"
              onClick={() => window.open(`${system?.url}`)}
            >
              {`${system?.url || ''}`}
            </p>
          </div>
          <div className="w-full lg:w-1/3 bg-gray-100 p-6  rounded-xl border shadow-md border-gray-50">
            <p className="text-gray-500 text-sm">Database</p>
            <p className="text-center text-xl font-bold">
              {`${system?.database?.name || '-'}`}{' '}
            </p>
          </div>
          <div className="w-full lg:w-1/3 bg-gray-100 p-6 rounded-xl border shadow-md border-gray-50">
            <p className="text-gray-500 text-sm"> MQTT</p>
            <p className="text-center text-xl font-bold">
              {`${system?.mqtt?.topic || '-'}`}{' '}
            </p>
          </div>
        </div>
        <Accordion>
          <AccordionSummary expandIcon={<i className="fas fa-chevron-down" />}>
            ข้อมูลทางเทคนิคทางด้านเซิฟเวอร์
          </AccordionSummary>
          <AccordionDetails>
            <div className="w-full flex p-4 lg:divide-x-2">
              <div className="w-full lg:w-1/2 ">
                <b>ดรอปเล็ต</b>
                <p>{`${system?.droplet?.name || '-'}`}</p>
                <p>Droplet ID : {`${system?.droplet?.dropletID || '-'}`}</p>
                <b>Networks</b>
                {_.map(system?.droplet?.networks?.v4, (address) => (
                  <div className="py-1" key={address?._id}>
                    - {`${address?.ip_address} ( ${address.type} )`}
                  </div>
                ))}
                <b>Region</b>
                <div className="px-4">
                  <p>{`${system?.droplet?.region?.name || ''}`}</p>
                  <p>slug : {`${system?.droplet?.region?.slug || '-'}`}</p>
                </div>
                <b>Size</b>
                <div className="px-4">
                  <p>
                    description :{' '}
                    {`${system?.droplet?.size?.description || '-'}`}
                  </p>
                  <p>slug : {`${system?.droplet?.size?.slug || '-'}`}</p>
                  <p>disk : {`${system?.droplet?.size?.disk || '-'}`}</p>
                  <p>memory : {`${system?.droplet?.size?.memory || '-'}`}</p>
                  <p>vcpus : {`${system?.droplet?.size?.vcpus || '-'}`}</p>
                  <p>
                    transfer : {`${system?.droplet?.size?.transfer || '-'}`}
                  </p>
                  <p>
                    price_monthly :{' '}
                    {`${system?.droplet?.size?.price_monthly || '-'}`}/month
                  </p>
                  <p>
                    available :{' '}
                    <Checkbox
                      defaultChecked={system?.droplet?.size?.available}
                      disabled
                    />
                  </p>
                </div>{' '}
              </div>
              <div className="w-full lg:w-1/2 px-2">
                <b>ดรอปเล็ตโปรเจค</b>
                <p>{`${system?.dropletProject?.name || '-'}`}</p>
                <p>
                  คำอธิบาย : {`${system?.dropletProject?.description || '-'}`}
                </p>
                <p>
                  จุดประสงค์ : {`${system?.dropletProject?.purpose || '-'}`}
                </p>
                <p>
                  Droplet project ID :{' '}
                  {`${system?.dropletProject?.dropletProjectID || '-'}`}
                </p>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<i className="fas fa-chevron-down" />}>
            Docker Container
          </AccordionSummary>
          <AccordionDetails>
            <div className="px-2">
              <b>Docker</b>
              <TableContainer>
                <Table size={'small'}>
                  <colgroup>
                    <col width="5%" />
                    <col width="10%" />
                    <col width="10%" />
                  </colgroup>
                  <TableHead>
                    <TableRow className="bg-gray-200">
                      <TableCell>
                        <div className="font-bold py-1">ลำดับที่</div>
                      </TableCell>
                      <TableCell>
                        <div className="font-bold py-1">ชื่อคอนเทนเนอร์</div>
                      </TableCell>
                      <TableCell>
                        <div className="font-bold py-1">พอร์ต</div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableHead>
                    {!_.isEmpty(system?.docker) ? (
                      _.map(system?.docker, (_docker, index) => (
                        <TableRow key={_docker?._id}>
                          <TableCell component="th" scope="row">
                            <div className="py-1">{`${index + 1}`}</div>
                          </TableCell>
                          <TableCell>
                            <div className="font-bold py-1">
                              {_docker?.containerName}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="font-bold py-1">
                              {_docker?.port}
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3}>
                          <div className="text-center">ไม่มีข้อมูล</div>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableHead>
                </Table>
              </TableContainer>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<i className="fas fa-chevron-down" />}>
            บัญชีผู้ใช้
          </AccordionSummary>
          <AccordionDetails>
            <div className="p-2">
              <b>บัญชีผู้ใช้</b>
              <TableContainer>
                <Table size={'small'}>
                  <colgroup>
                    <col width="5%" />
                    <col width="10%" />
                    <col width="10%" />
                    <col width="10%" />
                  </colgroup>
                  <TableHead>
                    <TableRow className="bg-gray-200">
                      <TableCell>
                        <div className="font-bold py-1">ลำดับที่</div>
                      </TableCell>
                      <TableCell>
                        <div className="font-bold py-1">ชิ่อผู้ใช้</div>
                      </TableCell>
                      <TableCell>
                        <div className="font-bold py-1">รหัสผ่าน</div>
                      </TableCell>
                      <TableCell>
                        <div className="font-bold py-1">สิทธิผู้ใช้</div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableHead>
                    {!_.isEmpty(system?.user) ? (
                      _.map(system?.user, (_user, index) => (
                        <TableRow key={_user?._id}>
                          <TableCell component="th" scope="row">
                            <div className="py-1">{`${index + 1}`}</div>
                          </TableCell>
                          <TableCell>
                            <div className="font-bold py-1">{_user?.user}</div>
                          </TableCell>
                          <TableCell>
                            <div className="font-bold py-1">
                              {_user?.password}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="font-bold py-1">
                              <Checkbox
                                defaultChecked={_user?.superuser}
                                disabled
                              />{' '}
                              Superuser
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4}>
                          <div className="text-center">ไม่มีข้อมูล</div>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableHead>
                </Table>
              </TableContainer>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );

  const renderNotes = () => (
    <div className="my-2">
      <Card>
        <CardContent>
          <div className="flex flex-wrap justify-between">
            <div>บันทึก</div>
            <div className="w-1/2 md:w-1/6">
              <FormControl fullWidth size="small">
                <InputLabel>Type ของการบันทึก</InputLabel>
                <Select
                  fullWidth
                  size="small"
                  label="Type ของการบันทึก"
                  value={selectedNotetype}
                  onChange={(e) => {
                    setSelectedNoteType(e.target.value);
                  }}
                >
                  <MenuItem value={''}>ไม่เลือก</MenuItem>
                  {_.map(NOTE_TYPE, (eachNoteType, index) => (
                    <MenuItem key={index} value={eachNoteType?.status_code}>
                      {eachNoteType?.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>

          <CommentBox
            comment={note}
            setSize={setSize}
            size={size}
            totalSize={note?.total}
            handleDelete={handleDeleteNote}
          />
        </CardContent>
      </Card>
      <Card className="my-2">
        <CardContent>
          <div>สร้างบันทึก</div>
          <form onSubmit={handleSubmit(handleCreateNote)}>
            <NoteForm control={control} />
            <div className="px-2">
              <ImageUpload
                images={images}
                maxNumber={5}
                setImages={setImages}
                title="อัพโหลดรูปภาพ (Max 5 Images)"
              />
            </div>
            <div className="w-full flex justify-end m-2">
              <Button variant="contained" type="submit">
                บันทึก
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );

  if (system.isLoading || system.rows) {
    return <Loading />;
  }

  if (!system.isLoading && system.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-between pb-4">
          <BackButton />
          <div className="flex gap-2">
            <Link to={`/monitor/system/module/detail/${id}`}>
              <Button variant="contained" color="secondary">
                สเปคระบบ
              </Button>
            </Link>
            <Link to={`/monitor/product/file/${system?.product?._id}`}>
              <Button variant="contained" color="info">
                คู่มือ/เอกสาร
              </Button>
            </Link>
            <Link to={`/monitor/system/edit/${id}`}>
              <Button variant="contained" color="warning">
                แก้ไข
              </Button>
            </Link>
          </div>
        </div>
        {renderDetail()}
        {renderNotes()}
      </div>
    );
  }

  return <Error message={system?.message} />;
}

DetailSystem.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailSystem.defaultProps = {
  title: '',
  subtitle: '',
};
