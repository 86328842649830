import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';
import { Button, Card } from '@mui/material';
import PropTypes from 'prop-types';
import _ from 'lodash';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/th';

import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { ViewTitle } from '../../components/ViewTitle';
import { CustomerForm } from '../../components/Form';
import { Loading } from '../../components/Loading';
import { ImageUpload } from '../../components/ImageUpload';
import { Error } from '../../components/Error';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

export default function EditCustomer({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const customer = useSelector((state) => state.customer);
  const [images, setImages] = useState([]);
  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {},
  });

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  useEffect(() => {
    dispatch(actions.customerGet(params.id));

    return () => {};
  }, [params]);

  useEffect(() => {
    if (!_.isEmpty(customer?.contacts)) {
      setValue('contacts', customer?.contacts);
    }

    return () => {};
  }, [customer]);

  const onSubmit = async (data) => {
    try {
      const payload = {
        ...data,
        logo: _.isEmpty(images)
          ? data.image
          : { image: images?.[0]?.data_url, alt: '' },
      };
      await dispatch(actions.customerPut(params.id, payload));
      history.goBack();
    } catch (error) {
      alert(error.message);
      console.error(error);
    }
  };

  const renderForm = () => (
    <div>
      <Card className="p-4">
        <form onSubmit={handleSubmit(onSubmit)}>
          <CustomerForm control={control} customer={customer} errors={errors} />
          <ImageUpload images={images} maxNumber={1} setImages={setImages} />
          <div className="flex flex-row justify-end gap-1 mt-2">
            <Button variant="contained" type="submit">
              บันทึก
            </Button>
          </div>
        </form>
      </Card>
    </div>
  );

  if (customer?.isLoading || customer?.rows) {
    return <Loading />;
  }

  if (customer?.isCompleted && !customer?.isLoading && !customer?.rows) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        {renderForm()}
      </div>
    );
  }

  return <Error />;
}

EditCustomer.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditCustomer.defaultProps = {
  title: '',
  subtitle: '',
};
