import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  Card,
  CardContent,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import _ from 'lodash';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/th';
import { useForm } from 'react-hook-form';

import * as actions from '../../redux/actions';

import { CommentBox } from '../Box';

import { NoteForm } from '../Form';
import { ImageUpload } from '../ImageUpload';
import { Loading } from '../Loading';
import { Error } from '../Error';
import { NOTE_TYPE } from '../../utils/constants';
dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

export default function NoteInProject() {
  const dispatch = useDispatch();

  const params = useParams();
  const note = useSelector((state) => state.note);

  const [noteSize, setNoteSize] = useState(5);
  const [images, setImages] = useState([]);
  const [selectedNotetype, setSelectedNoteType] = useState();
  const { control, handleSubmit, setValue } = useForm();

  useEffect(() => {
    dispatch(
      actions.noteAll({
        project: params.id,
        page: 1,
        size: noteSize,
        messageType: selectedNotetype,
      }),
    );

    return () => {};
  }, [noteSize, selectedNotetype]);

  const handleDeleteNote = async (noteId) => {
    try {
      const confirm = window.confirm('ยืนยันการลบ');
      if (confirm) {
        await dispatch(actions.noteDelete(noteId));
        dispatch(
          actions.noteAll({ project: params.id, page: 1, size: noteSize }),
        );
      }
    } catch (error) {
      alert(`ไม่สามารถลบได้ ${error?.message}`);
    }
  };

  const handleCreateNote = async (data) => {
    try {
      const payload = {
        ...data,

        project: params.id,

        images: _.isEmpty(images)
          ? []
          : _.map(images, (eachImage) => ({
              image: eachImage?.data_url,
              alt: '',
            })),
      };

      await dispatch(actions.noteCreate(payload));
      setValue('message', '');
      dispatch(
        actions.noteAll({ project: params.id, page: 1, size: noteSize }),
      );
    } catch (error) {
      alert(`สร้างบันทึกไม่สำเร็จ ${error?.message}`);
    }
  };
  if (note?.isLoading) {
    return <Loading />;
  }

  if (note?.isCompleted && !note?.isLoading) {
    return (
      <div>
        <Card>
          <CardContent>
            <div className="flex justify-between flex-wrap">
              <div>บันทึก</div>
              <div className="md:w-1/6">
                <FormControl fullWidth size="small">
                  <InputLabel>Type ของการบันทึก</InputLabel>
                  <Select
                    fullWidth
                    size="small"
                    label="Type ของการบันทึก"
                    value={selectedNotetype}
                    onChange={(e) => {
                      setSelectedNoteType(e.target.value);
                    }}
                  >
                    <MenuItem value={''}>ไม่เลือก</MenuItem>
                    {_.map(NOTE_TYPE, (eachNoteType, index) => (
                      <MenuItem key={index} value={eachNoteType?.status_code}>
                        {eachNoteType?.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <CommentBox
              comment={note}
              setSize={setNoteSize}
              size={noteSize}
              totalSize={note?.total}
              handleDelete={handleDeleteNote}
              displaySystem
            />
          </CardContent>
        </Card>
        <Card className="my-2">
          <CardContent>
            <div>สร้างบันทึก</div>
            <form onSubmit={handleSubmit(handleCreateNote)}>
              <NoteForm control={control} />
              <div className="px-2">
                <ImageUpload
                  images={images}
                  maxNumber={5}
                  setImages={setImages}
                  title="อัพโหลดรูปภาพ (Max 5 Images)"
                />
              </div>
              <div className="w-full flex justify-end m-2">
                <Button variant="contained" type="submit">
                  บันทึก
                </Button>
              </div>
            </form>
          </CardContent>
        </Card>
      </div>
    );
  }

  return <Error />;
}
