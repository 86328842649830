import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import {
  Button,
  Card,
  CardContent,
  TextField,
  Autocomplete,
} from '@mui/material';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/th';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

export default function EditSystemModule({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const system = useSelector((state) => state.system);
  const productModule = useSelector((state) => state.productModule);
  const { control, handleSubmit, setValue } = useForm({});
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'modules',
  });

  useEffect(() => {
    dispatch(actions.systemGet(id));
    return () => {};
  }, [id]);

  useEffect(() => {
    if (system) {
      dispatch(
        actions.productModuleAll({
          product: system?.product?._id,
          page: 1,
          size: 100,
        }),
      );
    }

    return () => {};
  }, [system]);

  useEffect(() => {
    if (system?.modules) {
      setValue('modules', system?.modules);
    }

    return () => {};
  }, [productModule]);

  const onSelectFromProdcutModule = () => {
    setValue('modules', productModule?.rows);
  };

  const onSubmit = async (data) => {
    try {
      await dispatch(actions.systemPut(id, { modules: data.modules }));
      history.goBack();
    } catch (error) {
      alert(`แก้ไขไม่สำเร็จ ${error?.message}`);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderDetail = () => (
    <div>
      {' '}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <CardContent>
            {_.map(fields, (eachField, index) => (
              <div key={eachField.id} className="flex flex-wrap my-2">
                <div className="w-full md:w-5/6 px-1">
                  {!_.isEmpty(productModule?.rows) && (
                    <Controller
                      name={`modules[${index}]`}
                      control={control}
                      defaultValue={eachField}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          options={productModule.rows}
                          fullWidth
                          onChange={(e, newValue) => {
                            field.onChange(newValue);
                          }}
                          getOptionLabel={(option) => `${option?.name || ''} `}
                          renderInput={(params) => (
                            <TextField {...params} label="โมดูล" size="small" />
                          )}
                        />
                      )}
                    />
                  )}
                </div>
                <div className="w-full md:w-1/6 px-1">
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => remove(index)}
                  >
                    ลบ
                  </Button>
                </div>
              </div>
            ))}
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => append({})}
              >
                เพิ่ม
              </Button>
            </div>
          </CardContent>
        </Card>{' '}
        <div className="flex justify-end my-2">
          <Button variant="contained" type="submit">
            บันทึก
          </Button>
        </div>
      </form>
    </div>
  );

  if (system.isLoading || system.rows) {
    return <Loading />;
  }

  if (!system.isLoading && system.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-between pb-4">
          <BackButton />
          <Button
            variant="contained"
            color="info"
            onClick={() => onSelectFromProdcutModule()}
          >
            ใส่ทุกโมดูล
          </Button>
        </div>
        {renderDetail()}
      </div>
    );
  }

  return <Error message={system?.message} />;
}

EditSystemModule.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditSystemModule.defaultProps = {
  title: '',
  subtitle: '',
};
