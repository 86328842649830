import React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

function DonutChart({ series, labels, useVariousColors = false, width = 300 }) {
  /**
   * @type {ApexOptions}
   */
  const options = {
    labels: labels || [],
    colors: useVariousColors ? [] : ['#008FFB', '#FF4560'],
  };

  return (
    <div>
      <Chart options={options} series={series} type="donut" width={width} />
    </div>
  );
}

export default DonutChart;
