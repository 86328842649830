import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import axios from 'axios';
import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { ChevronDown, ChevronUp } from 'react-feather';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Box,
  Collapse,
  Button,
} from '@mui/material';

function Row({ row, digitalOceanData, index }) {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <TableRow>
        <TableCell component="th" scope="row">
          <Button
            onClick={() => {
              setOpen(!open);
            }}
          >
            {open ? <ChevronUp /> : <ChevronDown />}
          </Button>
        </TableCell>
        <TableCell component="th" scope="row">
          <div className="py-1">{`${index + 1}`}</div>
        </TableCell>
        <TableCell component="th" scope="row">
          <div className="py-1">{`${row?.name}`}</div>
        </TableCell>
        <TableCell component="th" scope="row">
          {_.map(row.networks.v4, (address, index) => (
            <div
              key={index}
              className="py-1"
            >{`${address.ip_address} ( ${address.type} )`}</div>
          ))}
        </TableCell>
        <TableCell component="th" scope="row">
          <p>{`${row?.type?.name || '-'}`}</p>
          <p>{`${row?.type?.description || ''}`}</p>
          <p>{`${row?.type?.purpose || ''}`}</p>
        </TableCell>
        <TableCell component="th" scope="row">
          <div className="py-1">{`$${row?.size?.price_monthly}/mo`}</div>
        </TableCell>
        <TableCell component="th" scope="row">
          <div className="flex flex-wrap gap-1 uppercase">
            {_.map(digitalOceanData.droplets, (droplet) => {
              if (droplet.id == row.dropletID) {
                if (droplet.status === 'active') {
                  return (
                    <div key={droplet.id} className="text-green-500  font-bold">
                      {droplet.status}
                    </div>
                  );
                }
                if (droplet.status === 'off') {
                  return (
                    <div key={droplet.id} className="text-red-500  font-bold">
                      {droplet.status}
                    </div>
                  );
                } else {
                  return (
                    <div key={droplet.id} className="font-bold">
                      {droplet.status}
                    </div>
                  );
                }
              }
            })}
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <div className="p-2 px-4">ระบบในดรอปเล็ต</div>
              <div className="border-2 rounded-md">
                {_.size(row.systems) ? (
                  <Table sx={{ minWidth: 650 }} size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <div className="font-bold py-1">ลำดับที่</div>
                        </TableCell>
                        <TableCell>
                          <div className="font-bold py-1">ชื่อ</div>
                        </TableCell>
                        <TableCell>
                          {' '}
                          <div className="font-bold py-1">คำอธิบาย</div>
                        </TableCell>
                        <TableCell>
                          {' '}
                          <div className="font-bold py-1">URL</div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {_.map(row.systems, (system, index) => (
                        <TableRow key={system._id}>
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {system.name}{' '}
                            {system?.status === true ? (
                              <i
                                className="fa fa-check-circle green-color"
                                style={{ color: 'green' }}
                              ></i>
                            ) : (
                              <i
                                className="fa fa-times-circle green-color"
                                style={{ color: 'red' }}
                              ></i>
                            )}
                          </TableCell>

                          <TableCell> {system.description || ' - '}</TableCell>
                          <TableCell>
                            {' '}
                            <a
                              className="text-blue-600 hover:text-blue-400 hover:underline cursor-pointer"
                              onClick={() => window.open(`${system?.url}`)}
                            >{`${system?.url || ' - '}`}</a>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <div className="p-2 text-center">ไม่มีข้อมูล</div>
                )}
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function DropletDashboard({ title, subtitle }) {
  const dispatch = useDispatch();
  const droplets = useSelector((state) => state.droplet);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(40);
  const [total, setTotal] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [digitalOceanData, setDigitalOceanData] = useState(null);

  const [time, setTime] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 20000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios.get(
        `https://api.digitalocean.com/v2/droplets?page=${1}&per_page=${200}`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_DIGITALOCEAN_TOKEN}`,
          },
        },
      );
      setDigitalOceanData(data);
      dispatch(actions.dropletAll({ size, page }));
      setIsLoading(false);
    };

    fetchData();
    return () => {};
  }, [time]);

  useEffect(() => {
    setTotal(droplets?.total);
    return () => {};
  }, [droplets]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const renderDroplet = () => (
    <div className="mt-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size={'small'}>
            <TableHead>
              <TableRow className="bg-gray-200">
                <TableCell></TableCell>
                <TableCell>
                  <div className="font-bold py-1">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ชื่อ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">IP Address</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ประเภท</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ราคา</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">สถานะ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(droplets?.rows) ? (
                _.map(droplets?.rows, (row, index) => (
                  <Row
                    key={row._id}
                    row={row}
                    index={index}
                    digitalOceanData={digitalOceanData}
                  />
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  if (isLoading) {
    return <Loading />;
  }

  if (!droplets.isLoading && droplets.isCompleted && !isLoading) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        {renderDroplet()}
      </div>
    );
  }

  return <Error message={droplets?.message} />;
}

DropletDashboard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DropletDashboard.defaultProps = {
  title: '',
  subtitle: '',
};
