import React, { useContext, useEffect } from 'react';
import { Link as RouterLink, useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Button,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
} from '@mui/material';
import {
  BookOpen as BookOpenIcon,
  Code as CodeIcon,
  Activity as ActivityIcon,
  FileText as FileTextIcon,
  User as UserIcon,
  Droplet as DropletIcon,
  ShoppingBag,
  UserCheck,
  Users,
  Monitor as MonitorIcon,
  GitHub as GitHubIcon,
  Book as BookIcon,
} from 'react-feather';
import NavItem from './NavItem';
import { PassportAuth } from '../../contexts/AuthContext';
import { getStorage } from '../../utils/functions/localstorage';
// import accessRight from '../../utils/functions/accessRight';

const items = [
  {
    href: '/monitor/dashboard',
    icon: MonitorIcon,
    title: 'แดชบอร์ด',
    name: 'MONITOR',
    sub: [],
  },
  {
    href: '/monitor/system',
    icon: CodeIcon,
    title: 'ระบบของศูนย์วิจัย',
    name: 'MONITOR',
    sub: [],
  },
  {
    href: 'https://github.com/IARC-Programing',
    icon: GitHubIcon,
    title: 'GitHub ศูนย์วิจัย',
    name: 'MONITOR',
    outside: true,
    sub: [],
  },
  {
    href: 'https://doc.efactory.biz',
    icon: BookIcon,
    title: 'จัดการองค์ความรู้ (KM)',
    name: 'MONITOR',
    outside: true,
    sub: [],
  },
  {
    href: '/monitor/droplet',
    icon: DropletIcon,
    title: 'ดรอปเล็ต',
    name: 'MONITOR',
    sub: [],
  },
  {
    href: '/monitor/product',
    icon: ShoppingBag,
    title: 'โปรดักส์',
    name: 'MONITOR',
    sub: [],
  },
  {
    href: '/monitor/customer',
    icon: UserCheck,
    title: 'ลูกค้า',
    name: 'MONITOR',
    sub: [],
  },
  {
    href: '/monitor/droplet_project',
    icon: FileTextIcon,
    title: 'ดรอปเล็ตโปรเจค',
    name: 'MONITOR',
    sub: [],
  },
  {
    href: '/monitor/project',
    icon: BookOpenIcon,
    title: 'โครงการ',
    name: 'MONITOR',
    sub: [],
  },
  {
    href: '/monitor/advisor',
    icon: Users,
    title: 'ที่ปรึกษา',
    name: 'ADVISOR',
    sub: [],
  },
];

const DashboardSidebar = ({ me, onMobileClose, openMobile }) => {
  const {
    firstname = '',
    lastname = '',
    department: { name: departmentName = '' } = {},
  } = JSON.parse(getStorage('remember'));

  const user = {
    jobTitle: departmentName,
    name: `${firstname} ${lastname}`,
  };

  const { handleSignout } = useContext(PassportAuth);
  const history = useHistory();

  const onSignOut = async () => {
    await handleSignout();
    history.push('/login');
  };

  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box className="p-6">
        <div className="p-2 rounded-xl flex flex-wrap justify-center  bg-gray-50">
          <div className="lg:w-1/3 flex justify-center ">
            <div className="bg-gray-300 rounded-full p-1">
              <UserIcon size={40} color={'grey'} />
            </div>
          </div>
          <div className="w-full lg:w-2/3 text-center mt-3">
            <Typography color="textPrimary" variant="body2">
              {user.name}
            </Typography>
            {/* <Typography color="palevioletred" variant="body2">
              {user.jobTitle}
            </Typography> */}
          </div>
        </div>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {items.map((item) => {
            // if (accessRight(me, item.name)) {
            return (
              <div key={item.title}>
                <NavItem
                  href={item.href}
                  title={item.title}
                  icon={item.icon}
                  sub={item.sub}
                  me={me}
                  moduleName={item.name}
                  outside={item.outside}
                />
              </div>
            );
            // }
            // return <></>;
          })}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Divider />
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2,
        }}
      >
        <Button
          size="xs"
          startIcon={<i className="fas fa-sign-out-alt"></i>}
          variant="outlined"
          onClick={() => {
            onSignOut();
          }}
        >
          ออกจากระบบ
        </Button>
      </Box>
    </Box>
  );

  return (
    <div>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256,
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)',
              zIndex: 0,
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </div>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  me: PropTypes.object,
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default DashboardSidebar;
