import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Button,
  Card,
  TextField,
  InputAdornment,
  Modal,
  Fade,
  Backdrop,
} from '@mui/material';
import axios from 'axios';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as actions from '../../redux/actions';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  height: '70vh',
  boxShadow: 24,
  p: 3,
};

const defaultValue = { purpose: '', description: '' };

export default function DropletProjectManagement({ title, subtitle }) {
  const dispatch = useDispatch();
  const dropletProject = useSelector((state) => state.dropletProject);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(20);
  const [total, setTotal] = useState(undefined);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [digitalOceanData, setDigitalOceanData] = useState(null);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    const fetchProjectDroplet = async () => {
      const { data } = await axios.get(
        `https://api.digitalocean.com/v2/projects?page=${1}&per_page=${1000}`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_DIGITALOCEAN_TOKEN}`,
          },
        },
      );
      setDigitalOceanData(data);
      dispatch(actions.dropletProjectAll({ size, page }));
      setIsLoading(false);
    };
    fetchProjectDroplet();
    return () => {};
  }, [size, page]);

  useEffect(() => {
    setTotal(dropletProject?.total);
    return () => {};
  }, [dropletProject]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async () => {
    try {
      if (selected) {
        const data = selected;
        data.dropletProjectID = data.id;
        setSelected(null);
        handleClose();
        await dispatch(actions.dropletProjectCreate(data));
        await dispatch(actions.dropletProjectAll({ size, page }));
      } else {
        alert('กรุณาเลือกข้อมูล Project ที่ต้องการสร้าง');
      }
    } catch (error) {
      alert(error.message);
      console.error(error);
    }
  };

  const handleDeleteDropletProject = async (id) => {
    const confirm = window.confirm('ยืนยันการลบ');
    if (confirm) {
      await dispatch(actions.dropletProjectDelete(id));
      await dispatch(actions.dropletProjectAll({ size, page }));
    }
  };

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </Card>
  );

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const renderDropletProject = () => (
    <div className="mt-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size={'small'}>
            <TableHead>
              <TableRow className="bg-gray-200">
                <TableCell>
                  <div className="font-bold py-1">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">โปรเจค</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1 text-center">คำอธิบาย</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">จุดประสงค์</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(dropletProject?.rows) ? (
                _.map(dropletProject?.rows, (row, index) => (
                  <TableRow key={row?._id}>
                    <TableCell component="th" scope="row">
                      <div className="py-1">{`${
                        (page - 1) * size + index + 1
                      }`}</div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="py-1 cursor-pointer text-black font-bold">{`${row?.name}`}</div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="py-1 text-center">
                        {row?.description ? `${row?.description}` : '-'}
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="py-1">{`${row?.purpose}`}</div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="flex flex-wrap gap-1">
                        {/* <Button variant="outlined" color="warning" size="small">
                          แก้ไข
                        </Button> */}
                        <Button
                          variant="outlined"
                          color="error"
                          size="small"
                          onClick={() => {
                            handleDeleteDropletProject(row?._id);
                          }}
                        >
                          ลบ
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  const renderDigitalOceanDropletProjectList = () => (
    <div className="p-2">
      <TableContainer className="shadow-md rounded-md ">
        <Table sx={{ minWidth: 650 }} size={'small'}>
          <TableHead>
            <TableRow className="bg-gray-200">
              <TableCell>
                <div className="font-bold py-1">ลำดับที่</div>
              </TableCell>
              <TableCell>
                <div className="font-bold py-1">โปรเจค</div>
              </TableCell>
              <TableCell>
                <div className="font-bold py-1">ดำเนินการ</div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {digitalOceanData.projects.map((project, index) => (
              <TableRow key={project.id}>
                <TableCell component="th" scope="row">
                  <div className="py-1">{`${index + 1}`}</div>
                </TableCell>
                <TableCell component="th" scope="row">
                  <div className="py-1">{`${project?.name}`}</div>
                </TableCell>
                <TableCell component="th" scope="row">
                  {_.find(dropletProject.rows, (row) => {
                    return row.dropletProjectID === project.id;
                  }) ? (
                    <div>
                      <Button
                        variant="contained"
                        color="info"
                        size="small"
                        disabled
                      >
                        มีอยู่แล้ว
                      </Button>
                    </div>
                  ) : project.id === selected?.id ? (
                    <Button
                      variant="contained"
                      color="success"
                      size="small"
                      onClick={() => {
                        setSelected(null);
                      }}
                    >
                      เลือก
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      color="success"
                      size="small"
                      onClick={() => {
                        setSelected(project);
                      }}
                    >
                      เลือก
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );

  const renderModal = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Card sx={style} className="max-w-4xl">
          <div className="text-lg font-semibold">เพิ่มข้อมูล</div>
          <div className="py-2">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="overflow-y-scroll" style={{ maxHeight: '50vh' }}>
                {renderDigitalOceanDropletProjectList()}
              </div>
              <div className="flex flex-row justify-end gap-1">
                <Button variant="contained" type="submit">
                  บันทึก
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    handleClose();
                    setSelected(null);
                  }}
                >
                  ยกเลิก
                </Button>
              </div>
            </form>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderAddButton = () => (
    <div className="flex flex-row justify-end mb-4">
      <Button variant="contained" size="small" onClick={handleOpen}>
        เพิ่ม
      </Button>
    </div>
  );

  if (isLoading) {
    return <Loading />;
  }

  if (!dropletProject.isLoading && dropletProject.isCompleted && !isLoading) {
    return (
      <div>
        {renderModal()}
        {renderTitle()}
        <div className="flex flex-row justify-end">
          <div>{renderAddButton()}</div>
        </div>
        {renderSearch()}
        {renderDropletProject()}
      </div>
    );
  }

  return <Error message={dropletProject?.message} />;
}

DropletProjectManagement.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DropletProjectManagement.defaultProps = {
  title: '',
  subtitle: '',
};
