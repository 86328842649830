import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useParams, Link } from 'react-router-dom';
import {
  Button,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TablePagination,
  Fade,
  Card,
  Modal,
  Backdrop,
} from '@mui/material';
import PropTypes from 'prop-types';
import _ from 'lodash';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/th';
import ReactMarkdown from 'react-markdown';

import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { ViewTitle } from '../../components/ViewTitle';
import { ProductModuleForm } from '../../components/Form';

import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  boxShadow: 24,
  p: 3,
};

export default function ProductModule({ title, subtitle }) {
  const dispatch = useDispatch();

  const params = useParams();
  const product = useSelector((state) => state.product);
  const productModule = useSelector((state) => state.productModule);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState();
  const [open, setOpen] = useState(false);

  const renderTitle = () => (
    <ViewTitle title={`${title} ${product?.name}`} subtitle={subtitle} />
  );

  useEffect(() => {
    dispatch(actions.productGet(params.id));

    return () => {};
  }, [params]);

  useEffect(() => {
    dispatch(
      actions.productModuleAll({ product: params.id, name: '', page, size }),
    );

    return () => {};
  }, []);

  useEffect(() => {
    if (productModule?.size) {
      setTotal(productModule?.total);
    }

    return () => {};
  }, [productModule]);

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const onSubmit = async (data) => {
    try {
      await dispatch(
        actions.productModuleCreate({ ...data, product: params.id }),
      );
      alert('สำเร็จ');
      setOpen(false);
      reset();
      dispatch(
        actions.productModuleAll({ name: '', size, page, product: params.id }),
      );
    } catch (error) {
      alert(error.message);
      console.error(error);
    }
  };

  const handleDeleteProductModule = async (id) => {
    const confirm = window.confirm('ยืนยันการลบ');
    if (confirm) {
      await dispatch(actions.productModuleDelete(id));
      await dispatch(
        actions.productModuleAll({ size, page, name: '', product: params.id }),
      );
    }
  };

  const renderModal = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={() => setOpen(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Card sx={style} className="max-w-4xl">
          <div className="text-lg font-semibold">เพิ่มข้อมูล</div>
          <div className="py-2">
            <form onSubmit={handleSubmit(onSubmit)}>
              <ProductModuleForm control={control} errors={errors} />
              <div className="flex flex-row justify-end gap-1 mt-2">
                <Button variant="contained" type="submit">
                  บันทึก
                </Button>
                <Button variant="outlined" onClick={() => setOpen(false)}>
                  ยกเลิก
                </Button>
              </div>
            </form>
          </div>
        </Card>
      </Fade>
    </Modal>
  );
  const renderData = () => (
    <div className="mt-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size={'small'}>
            <colgroup>
              <col width="10%" />
              <col width="20%" />
              <col width="40%" />
              <col width="20%" />
            </colgroup>
            <TableHead>
              <TableRow className="bg-gray-200">
                <TableCell>
                  <div className="font-bold py-1">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ชื่อโมดูล</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ฟีเจอร์</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableHead>
              {!_.isEmpty(productModule?.rows) ? (
                _.map(productModule?.rows, (row, index) => (
                  <TableRow key={row?._id}>
                    <TableCell component="th" scope="row">
                      <div className="py-1">{`${
                        (page - 1) * size + index + 1
                      }`}</div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="py-1">{row?.name}</div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="md-content">
                        <ReactMarkdown children={row?.feature || '-'} />
                      </div>
                    </TableCell>

                    <TableCell component="th" scope="row">
                      <div className="flex flex-wrap gap-1">
                        <Link to={`/monitor/product-module/edit/${row?.id}`}>
                          <Button
                            variant="outlined"
                            color="warning"
                            size="small"
                          >
                            แก้ไข
                          </Button>
                        </Link>
                        <Button
                          variant="outlined"
                          color="error"
                          size="small"
                          onClick={() => {
                            handleDeleteProductModule(row?._id);
                          }}
                        >
                          ลบ
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableHead>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  if (product?.isLoading || product?.rows) {
    return <Loading />;
  }

  if (product?.isCompleted && !product?.isLoading && !product?.rows) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-between pb-4">
          <div>
            <BackButton />
          </div>
          <div>
            <Button variant="contained" onClick={() => setOpen(true)}>
              เพิ่ม
            </Button>
          </div>
        </div>
        {renderData()}
        {renderModal()}
      </div>
    );
  }

  return <Error />;
}

ProductModule.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ProductModule.defaultProps = {
  title: '',
  subtitle: '',
};
